.parsed-content {
  font-size: 14px;
  color: var(--text2);
  margin-bottom: 0;
  white-space: pre-wrap;

  a {
    color: var(--link) !important;
  }

  br {
    display: none;
  }

  &__text--fading {
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  }

  &__action-btn {
    font-size: 14px;
    font-weight: 600 !important;
    color: var(--link) !important;
    vertical-align: 1px;
  }
}
