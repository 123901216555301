.becomeCreator {
  display: flex;
  padding: 0 6px;
  background-color: var(--background);
  position: relative;
  z-index: 1;
  width: 100%;
  border: none;
  margin: 0;
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  img {
    width: 100px;
    height: 80px;
    margin-top: 12px;
  }
  .becomeCreatorContent {
    width: 100%;
    margin: 0px 12px;
  }
  .becomeCreatorTitle {
    font-size: 16px;
    color: var(--text1);
    font-weight: 600;
    margin-bottom: 2px;
  }
  .becomeCreatorText {
    font-size: 11px;
    color: var(--text3);
    line-height: 16px;
  }
  .becomeCreatorArrow {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    svg {
      width: 24px;
    }
  }
}
