.rating_sheet {
  [data-rsbs-header] {
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .rating_sheet__rate-course__container {
    padding: 12px;
    border-bottom: 1px solid var(--border);
    .rating_sheet__rate-course {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .rating_sheet__rate-course__star__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        .rating_sheet__rate-course__star {
        }
      }
      .rating_sheet__rate-course__text {
        font-size: 14px;
        font-weight: 500;
        color: var(--text1);
        margin: 0;
      }
    }
  }
  .rating_sheet__review-course__container {
    padding: 12px;
    border-bottom: 1px solid var(--border);
    .rating_sheet__review-course__input {
      background-color: var(--tertiary);
      border-radius: 8px;
      padding: 12px;
      font-size: 14px;
      font-weight: 400;
      color: var(--text1);
      width: 100%;
      border: 0;
      outline: 0;
      min-height: 150px;
      text-align: left;
    }
  }
}
