.activity-card {
  background-color: var(--background);
  padding: 12px;
  .activity-card__images {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0 24px 0;
    .activity-card__images__mango {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .activity-card__content {
    h4 {
      color: var(--text1);
      font-weight: 500;
      font-size: 18px;
    }
    p {
      color: var(--text3);
      font-weight: 400;
      font-size: 14px;
    }
  }
}
