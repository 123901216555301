.assessment-item {
    padding: 12px;
    background-color: var(--gray_light);
    .assessment-item__title {
        font-size: 16px;
        font-weight: 500;
        color: var(--text1);
        margin: 0;
    }
    .assessment-item__description {
        font-size: 13px;
        font-weight: 400;
        color: var(--text3);
        margin: 0;
        margin-top: 4px;
    }
    .assessment-item__button-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 18px;
    }
    .status-pill {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        .status-pill__text {
            font-size: 10px;
            font-weight: 500;
            color: var(--text2);
        }
    }
    .completed_assessment-item__title {
        font-size: 16px;
        font-weight: 500;
        color: var(--text1);
        margin: 0;
    }
    .completed_assessment-item__subtitle {
        font-size: 11px;
        font-weight: 400;
        color: var(--text3);
        margin: 0;
        margin-top: 2px !important;
    }
    .completed_assessment-item__notification {
        margin-top: 12px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        padding: 4px 8px;

        .completed_assessment-item__notification-text {
            font-size: 10px;
            font-weight: 500;
            color: var(--text2);
        }
    }
}
