.uploadingComponent {
  background-color: var(--background);
  .uploadingComponentContent {
    padding: 12px;
    .uploadingComponentContentTitle {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 6px;
      color: var(--text1);
    }
  }
}
