.qna__item {
  padding: 12px;
  display: flex;
  flex-direction: row;
  .qna__item__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .qna__item__header__title {
      font-size: 13px;
      font-weight: 600;
      color: var(--text1);
      margin: 0;
      .qna__item__header__title__time {
        font-size: 13px;
        font-weight: 400;
        color: var(--text3);
        margin: 0;
      }
    }
    .qna__item__header__menu__wrapper {
      height: 14px;
      transform: translate(-4px, -6px);
    }
  }
  .qna__item__question {
    font-size: 13px;
    font-weight: 600;
    color: var(--text1);
    margin: 0;
    margin-block: 8px;
  }
  .qna__item__description {
    font-size: 13px;
    font-weight: 400;
    color: var(--text2);
    margin: 0;
    .ant-typography-expand {
      font-size: 13px;
      font-weight: 600;
      color: var(--link);
    }
  }
  .qna__item__reply {
    font-size: 12px;
    font-weight: 500;
    color: var(--link);
    margin: 0;
    margin-top: 12px;
    display: flex;
    align-items: center;
    .qna__item__reply__separator {
      font-size: 12px;
      font-weight: 400;
      color: var(--text4);
      margin: 0 8px;
    }
    .qna__item__reply__answered {
      font-size: 12px;
      font-weight: 500;
      color: var(--green);
      margin: 0;
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
}
