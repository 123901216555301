.mangoChatScreenHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 35px 15px 45px;
  .avatarHolder {
    display: block;
    border-radius: 100%;
    height: 76px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .camera__container {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    position: absolute;
    left: -5%;
    bottom: 5%;
    background-color: var(--secondary);
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .avatar-uploader {
    display: block;
    width: max-content;
    height: 76px;
  }
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
    border-radius: 100%;
    border: 0;
    display: block;
    width: max-content;
    height: 76px;

    > .ant-upload {
      padding: 0px;
    }
  }
  .mangoChatScreenHeader__title {
    font-size: 17px;
    font-weight: 600;
    color: var(--text1);
    margin: 12px 0;
  }

  .mangoChatScreenHeader__participants {
    display: flex;
    flex-direction: row;
    align-items: center;
    .participant-extra-text__wrapper {
      height: 22px;
      min-width: 22px;
      border-radius: 22px;
      border: 1px solid var(--border);
      background-color: var(--background);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -8px;
      padding: 2px 6px;
      z-index: 1;
      position: relative;
      .participant-extra-text {
        font-size: 9px;
        font-weight: 500;
        color: var(--text1);
        margin: 0;
      }
    }
    .mangoChatScreenHeader__participants__extra--text {
      font-size: 11px;
      font-weight: 500;
      color: var(--text1);
      margin: 0;
      margin-left: 6px;
    }
  }
}
