.chapter__details {
  .chapter__details__header {
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .chapter__index {
      font-size: 16px;
      font-weight: 600;
      color: var(--text1);
      margin: 0;
      margin-right: 12px;
    }
    .chapter__title {
      font-size: 16px;
      font-weight: 600;
      color: var(--text1);
      margin: 0;
    }
    .chapter__subtitle {
      font-size: 13px;
      font-weight: 500;
      color: var(--text2);
      margin: 0;
      margin-top: 4px !important;
    }
  }
  .tab__selector {
    border-top: 1px solid var(--border);
    position: sticky;
    top: -1px;
    z-index: 1;
  }
  .tab__content__locked {
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: var(--gray_lighter);
    border-radius: 200px;
    margin-top: 36px;
  }
  .tab__content__about {
    padding: 12px;
    font-size: 14px;
    * {
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
  .chapter__section__title {
    padding: 12px;
    margin: 0;
    font-size: 14px;
    color: var(--text1);
    font-weight: 600;
  }

  .qna__section__header {
    padding: 12px;
    border-bottom: 1px solid var(--border);
    display: flex;
    align-items: center;
    cursor: pointer;
    .qna__section__header__text {
      font-size: 13px;
      font-weight: 400;
      color: var(--text3);
      margin: 0;
      margin-left: 12px;
    }
  }

  .commentInputBox {
    align-items: end;
    .commentInputBox__input {
      width: 100%;
      border: none;
      background-color: transparent;
      font-size: 14px;
      color: var(--text1);
      font-weight: 400;
      line-height: 25px;
      min-height: 25px;
      // padding: 5px 0;
      outline: 0;
      resize: none;
      max-height: 100px;
      overflow-y: auto;
      direction: ltr;
      text-align: left;
      word-break: break-word;
      margin: 0 12px;
      // min-height: 40px;
      background-color: var(--gray_lighter);
      padding: 6px 12px;
      border-radius: 18px;
    }
    .commentInputBox__sendIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background-color: var(--primary);
      border-radius: 50%;
      flex-shrink: 0;
      padding: 0;
      border: none;
      .ant-spin-dot-item {
        background-color: var(--background);
      }
    }
  }
}
