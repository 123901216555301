.resource-item {
    padding: 12px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--tertiary);
    cursor: pointer;
    svg {
        flex-shrink: 0;
    }
    .resource-item__content {
        display: flex;
        flex: 1;
        margin-left: 12px;
        flex-direction: column;
        width: calc(100% - 75px);
        .resource-item__content__title {
            font-weight: 500;
            color: var(--text1);
            font-size: 13px;
            margin: 0;
        }
        .resource-item__content__subtitle {
            font-size: 11px;
            color: var(--text2);
            font-weight: 500;
            margin: 0;
        }
    }
    .resource-item__action {
        padding: 3px;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
    }
}
