.courseOverview {
  background-color: var(--page_bg) !important;
  .courseOverview__cover {
    width: 100%;
    aspect-ratio: 16/9;
    max-height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(5px);
    }
    .courseOverview__coverImage {
      position: relative;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .courseOverview__coverImage__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      .courseOverview__coverImage__overlay__icon {
        background-color: var(--primary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;

        svg {
          height: 32px;
          width: 32px;
          fill: var(--background);
        }
      }
    }
  }
  .courseOverview__card__wrapper {
    margin-bottom: 6px;
  }
  .courseOverview__card {
    background-color: var(--background);
    padding: 12px;
    // margin-bottom: 6px;
    .courseOverview__cardTitle {
      font-size: 14px;
      font-weight: 600;
      color: var(--text1);
      margin-bottom: 6px;
    }
    .courseOverview__cardSubTitle {
      font-size: 12px;
      font-weight: 400;
      color: var(--text1);
      margin: 0;
    }
  }
  .titleCard {
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    .courseOverview__title {
      font-size: 17px;
      font-weight: 600;
      color: var(--text1);
      margin: 0;
    }
    .courseOverview__subTitle {
      font-size: 11px;
      color: var(--text1);
      font-weight: 500;
    }
  }
  .progressCard {
    // padding-top: 0;
    .progressCard__inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .progressCard__contents {
        flex-shrink: 0;
        flex-grow: 1;
        margin-right: 16px;
        .progressCard__innerText {
          font-size: 12px;
          font-weight: 600;
          color: var(--text2);
          margin: 0;
          span {
            font-weight: 400;
          }
        }
        .progressCard__innerText__light {
          font-size: 12px;
          font-weight: 400;
          color: var(--green);
          margin: 0;
          margin-bottom: 6px;
        }
        .progressbar {
          margin: 4px 0;
          // .progressbar__inner {
          //   background-color: var(--primary);
          // }
        }
      }
      .siteBtn {
        width: 100%;
        span {
          font-size: 14px;
          font-weight: 500;
          margin-right: 6px;
          color: var(--background);
        }
      }
      div ~ .siteBtn {
        max-width: 150px;
      }
    }
  }
  .descriptionCard {
    .descriptionCard__description {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .courseOverview__description__list {
    margin-bottom: 12px;
    background-color: var(--gray_light);
    border-radius: 8px;
    padding: 12px;
    .courseOverview__description__list__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 6px;
      }
      span {
        font-size: 12px;
        font-weight: 500;
        margin-left: 8px;
      }
    }
  }
  .shareCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 12px;
      span {
        font-size: 13px;
        font-weight: 500;
        color: var(--text1);
        margin: 0;
        margin-left: 12px;
      }
    }
  }
  .helpCard {
    padding: 12px;
    text-align: center;
    > span {
      font-size: 12px;
      font-weight: 400;
      color: var(--text1);
      margin: 0;
      a {
        font-weight: 600;
        color: var(--blue);
      }
    }
  }
  .sectionsCard {
    padding: 0;
    .courseOverview__cardTitle {
      margin-bottom: 0;
    }
    .siteBtn {
      width: 100%;
      background-color: var(--background);
      border-radius: 0;
      color: var(--blue);
      padding: 16px 12px;
      span {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .courseOverview__completedcard__container {
    .courseOverview__completedcard__certificate {
      // background-color: var(--primary_light);
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      padding: 12px;
      border-radius: 8px 8px 0 0;
      .courseOverview__completedcard__certificate__icon {
        height: 40px;
        width: 40px;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: var(--warning);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .courseOverview__completedcard__certificate__title {
        font-size: 14px;
        font-weight: 400;
        color: var(--text1);
        margin-bottom: 4px;
        b {
          font-weight: 500;
        }
      }
      .courseOverview__completedcard__certificate__subTitle {
        font-size: 14px;
        font-weight: 600;
        color: var(--link);
        margin: 0;
      }
    }
    .courseOverview__completedcard__rate-course {
      padding-top: 12px;
      background-color: var(--primary_light);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      padding: 12px;
      border-radius: 0 0 8px 8px;
      .courseOverview__completedcard__rate-course__star__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        // .courseOverview__completedcard__rate-course__star {
        // }
      }
      .courseOverview__completedcard__rate-course__text {
        font-size: 14px;
        font-weight: 500;
        color: var(--text1);
        margin: 0;
      }
    }
  }
}
