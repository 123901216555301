@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin nonselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#button-background {
  position: relative;
  //   background-image: radial-gradient(circle at 100% 46%, #21d2a3, #15adaf 102%);
  width: 100%;
  height: 48px;
  border-radius: 6px;
  @include center;
}

#slider {
  transition: width 0.3s, border-radius 0.3s, height 0.3s;
  position: absolute;
  left: 0px;
  width: 48px;
  height: 48px;
  border-radius: 6px;
  @include center;
  //   &.unlocked {
  //     transition: all 0.3s;
  //     left: 0 !important;
  //     left: calc(50% - 26px) !important;
  //     height: inherit;
  //     border-radius: inherit;
  //     animation-name: spin;
  //     animation-duration: 500ms;
  //     animation-iteration-count: infinite;
  //     animation-timing-function: linear;
  //   }
}
.loading {
  display: none;
}
.slide-text {
  //   font-family: 'Roboto', sans-serif;
  @include nonselect;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
}
