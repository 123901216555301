.deleteAccount {
    padding: 12px;
    .deleteAccount__image {
        display: block;
        width: 80%;
        margin: 0 auto;
    }
    .deleteAccount__title {
        display: flex;
        gap: 6px;
        .deleteAccount__title--text {
            color: var(--danger);
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 12px;
        }
    }
    .deleteAccount__list {
        .deleteAccount__list--item {
            color: var(--text3);
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 4px;
        }
    }
    .deleteAccount__text {
        color: var(--text1);
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 12px;
    }
}
