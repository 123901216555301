.courses__container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .courses__content {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    background-color: var(--background);
  }
}

.tab__selector {
  padding: 8px 12px;
  height: 55px;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  background-color: var(--background);
  border-top: 1px solid var(--border);
  overflow-x: auto;
  .tab__selector__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    margin: 10px 0;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: transparent;
    margin: 0;
    .tab__selector__item__text {
      font-size: 14px;
      font-weight: 600;
      color: var(--text3);
    }
    &.selected {
      background-color: var(--primary_light);
      .tab__selector__item__text {
        color: var(--primary);
      }
    }
    .tab__selector__item__count {
      font-size: 12px;
      font-weight: 500;
      color: var(--text3);
      background-color: var(--danger);
      border-radius: 20px;
      height: 20px;
      min-width: 20px;
      color: var(--background);
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      margin-left: 8px;
    }
  }
}

.tags-filter__container {
  height: 45px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid var(--border);
  background-color: var(--background);
  width: 100%;
  .tags-filter__text {
    padding-left: 12px;
    min-width: max-content;
    display: flex;
    align-items: center;
    height: 100%;
    &::after {
      content: '';
      width: 1px;
      height: 20px;
      background-color: var(--border);
      margin-left: 12px;
    }
    p {
      font-size: 12px;
      font-weight: 600;
      color: var(--text1);
      margin: 0;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        position: absolute;
        bottom: 0px;
        content: '';
        display: block;
        height: 2px;
        width: 100%;
        background-color: var(--text1);
      }
    }
  }
  .tags-filter__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12px;
    overflow-x: auto;
    height: 100%;
    .tags-filter__tag {
      min-width: max-content;
      margin-right: 6px;
      padding: 3px 10px;
      border-radius: 50px;
      font-size: 13px;
      font-weight: 500;
      border: 1px solid var(--border);
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
      span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        color: var(--text2);
      }
      &.active {
        border-color: var(--primary);
        span {
          color: var(--primary);
        }
      }
    }
  }
}
