.notFoundWrapper {
    position: relative;
    width: 100%;
    min-height: var(--app-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-result .ant-result-icon img {
        width: 75%;
    }

    padding: 100px 0 45px;

    .ant-result .ant-result-icon img {
        width: 100%;
    }
}
