.loader__wrapper {
  height: var(--app-height);
  width: 100vw;
  display: grid;
  place-content: center;

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }
    div:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: var(--primary);
      margin: -4px 0 0 -4px;
    }
    div:nth-child(1) {
      animation-delay: -0.036s;
    }
    div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    div:nth-child(2) {
      animation-delay: -0.072s;
    }
    div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    div:nth-child(3) {
      animation-delay: -0.108s;
    }
    div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    div:nth-child(4) {
      animation-delay: -0.144s;
    }
    div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    div:nth-child(5) {
      animation-delay: -0.18s;
    }
    div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    div:nth-child(6) {
      animation-delay: -0.216s;
    }
    div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    div:nth-child(7) {
      animation-delay: -0.252s;
    }
    div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    div:nth-child(8) {
      animation-delay: -0.288s;
    }
    div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
    @keyframes lds-roller {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.basic--layout__wrapper {
  display: flex;
  flex-direction: column;
  height: var(--app-height);
  .basic--layout__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-shrink: 0;
  }
  .bottom--area,
  .top--area {
    background-color: var(--background);
  }
}

//global styles
.siteBtn {
  border-radius: 6px;
  border: 0px;
  padding: 9px 25px;
  height: auto;
  color: var(--background);
  box-shadow: none;

  > span {
    font-size: 16px;
    font-weight: 600;
  }

  &.sm {
    & > span {
      font-size: 14px;
    }
  }

  &.noStyle {
    -webkit-appearance: none;
    padding: 0;
    height: auto;
    border: 0;
    box-shadow: none;
    outline: 0;
    background: transparent;
    display: flex;
    align-items: center;
    border-radius: 0;

    &:disabled,
    &.disabled {
      opacity: 0.5;
      filter: grayscale(100%);
      color: var(--background);
      border: 0px;
      cursor: not-allowed;

      &:hover,
      &:active,
      &:focus {
        opacity: 0.5;
        filter: grayscale(100%);
        color: var(--background);
        border: 0px;
      }
    }
  }

  &.circularBgHover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    &:hover,
    &:active,
    &:focus {
      background-color: rgba(0, 0, 0, 0.1);
    }
    img,
    svg {
      width: 18px;
      height: 18px;
    }
  }

  &.siteBtnSecondary {
    background-color: var(--darkblue);
    justify-content: center;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
      color: var(--background);
    }

    &:disabled {
      opacity: 0.5;
      filter: grayscale(100%);
      color: var(--background);
      border: 0px;
      background-color: var(--darkblue);

      &:hover,
      &:active,
      &:focus {
        opacity: 0.5;
        filter: grayscale(100%);
        color: var(--background);
        border: 0px;
        background-color: var(--darkblue);
      }
    }
  }

  &.siteBtnDanger {
    background-color: var(--danger);

    &:hover,
    &:focus {
      color: var(--background);
    }
  }

  &.siteBtnSuccess {
    background-color: var(--green);
    display: flex;
    align-items: center;
    span {
      // padding-left: 10px;
      font-weight: 600;
    }
    &:hover,
    &:focus {
      color: var(--background);
      filter: brightness(95%);
    }
    &:disabled,
    &.disabled {
      opacity: 0.5;
      filter: grayscale(100%);
      color: var(--background);
      border: 0px;
      cursor: not-allowed;

      &:hover,
      &:active,
      &:focus {
        opacity: 0.5;
        filter: grayscale(100%);
        color: var(--background);
        border: 0px;
        background-color: var(--green);
      }
    }
  }

  &.siteBtnExcel {
    background-color: #1d6f42;

    &:hover,
    &:focus {
      color: var(--background);
    }

    &:disabled {
      opacity: 0.5;
      filter: grayscale(100%);
      color: var(--background);
      border: 0px;
      background-color: #1d6f42;

      &:hover,
      &:active,
      &:focus {
        opacity: 0.5;
        filter: grayscale(100%);
        color: var(--background);
        border: 0px;
        background-color: #1d6f42;
      }
    }
  }

  &.siteBtnPrimary {
    background-color: var(--primary);
    justify-content: center;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
      color: var(--background);
    }

    &:disabled {
      opacity: 0.5;
      filter: grayscale(100%);
      color: var(--background);
      border: 0px;
      background-color: var(--primary);

      &:hover,
      &:active,
      &:focus {
        opacity: 0.5;
        filter: grayscale(100%);
        color: var(--background);
        border: 0px;
        background-color: var(--primary);
      }
    }

    &.light {
      background: var(--primary_light) !important;
      color: var(--primary) !important;
      &:hover,
      &:focus {
        color: var(--primary) !important;
      }
    }
  }

  &.siteBtnLinkv2 {
    background-color: var(--blue);

    &:hover,
    &:focus {
      color: var(--background);
    }
  }

  &.siteBtnDefault {
    border: 2px solid var(--border);
    color: var(--text2);

    &:hover,
    &:focus {
      color: var(--text2);
    }
  }

  &.siteBtnLink {
    padding: 0;
    color: var(--primary);
    display: flex;
    align-items: center;
    background-color: transparent;

    &.blue {
      color: var(--blue);

      &:hover,
      &:focus {
        color: var(--blue);
      }
    }

    &.white {
      color: var(--background);

      &:hover,
      &:focus {
        color: var(--background);
      }
    }

    &.red {
      span {
        color: var(--danger) !important;
      }

      &:hover,
      &:focus {
        span {
          color: var(--danger) !important;
        }
      }
    }

    &.green {
      color: var(--green);

      &:hover,
      &:focus {
        color: var(--green);
      }
    }

    &.gray {
      color: rgba(0, 0, 0, 0.25);

      &:hover,
      &:focus {
        color: rgba(0, 0, 0, 0.25);
      }
    }

    &.text {
      color: var(--text1);

      &:hover,
      &:focus {
        color: var(--text1);
      }
    }

    &.dark {
      color: var(--text1);
      // text-decoration: underline;
      align-items: baseline;
      span {
        padding-right: 8px;
      }
      &:hover,
      &:focus {
        color: var(--text1) !important;
      }
    }

    &:hover,
    &:focus {
      color: var(--primary);
    }
    &[disabled] {
      color: var(--text5);
      cursor: not-allowed;
    }
  }

  &.grayBtn {
    height: 36px !important;
    background-color: var(--gray_light) !important;
    border-radius: 4px !important;
    padding: 0 16px !important;
    justify-content: center;
    @media screen and (max-width: 767px) {
      padding: 0 10px !important;
    }
    > span {
      color: var(--text2);
      font-size: 14px;
      font-weight: 600;
    }
    &:hover,
    &:focus,
    &:active {
      filter: brightness(95%);
    }
  }

  &.blueBtnV2 {
    height: 36px !important;
    background-color: #e7f3ff !important;
    border-radius: 4px !important;
    padding: 0 15px !important;
    justify-content: center;
    span {
      color: #1877f2;
      font-size: 14px;
      font-weight: 600;
    }
    &:hover,
    &:focus {
      filter: brightness(95%);
    }
  }
}

.ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageRoot {
  height: var(--app-height);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .pageContent {
    height: 100%;
    overflow-y: auto;
    background-color: var(--background);
    .pageSubtitle {
      font-size: 16px;
      color: var(--text2);
      margin-bottom: 12px;
    }
    iframe {
      height: 100%;
      width: 100%;
      border: none;
    }
    &.pageContentList {
      background-color: var(--page_bg);
    }
  }
  .pageFooter {
    padding: 12px;
    border-top: 1px solid var(--border);
    button {
      width: 100%;
    }
  }
}

.ant-divider {
  color: var(--border) !important;
  margin: 12px 0;
}

.floatingLabelInputWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  margin-bottom: 12px;

  &.border {
    border-bottom: 1px solid var(--border);
  }

  .floatingLabelWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    .floatingLabel {
      color: var(--text3);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.025em;
    }
  }

  .inputValue > p {
    font-weight: 500;
    font-size: 16px;
    max-width: 90%;
  }
  .inputValue {
    font-weight: 500;
    font-size: 16px;
    max-width: 90%;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
}

// +++++++++++++++++++++++++++++++++ Mail Editor Styling +++++++++++++++++++++++++++++++++++ //
.emailBodyEditorWrapper {
  border: 1px solid #ced0d4;
  border-radius: 6px;
  transition: 0.25s ease;

  .toolbarClassName {
    padding: 12px 13px !important;
    border: 0 !important;
    border-bottom: 1px solid #ced0d4 !important;
    border-radius: 0 !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    margin: 0 !important;
    background-color: lighten(#f5f5f5, 2%) !important;

    .rdw-link-wrapper {
      .rdw-link-modal {
        height: 255px;
      }
    }
  }

  .rdw-option-wrapper {
    padding: 14px 8px !important;
  }

  .editorClassName {
    padding: 5px 18px 25px !important;
    min-height: 165px;
  }

  .public-DraftEditorPlaceholder-inner {
    color: #9e9e9e;
    font-size: 16px;
    font-weight: 400;
  }

  // .rdw-dropdown-selectedtext {
  //   padding: 0 10px !important;
  //   justify-content: space-between;
  // }

  .blockTool,
  .fontTool {
    .rdw-dropdown-selectedtext {
      font-weight: 500;
      color: #1a1a1a;
    }
  }

  .toolbarDropdown,
  .toolbarPopup {
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.2),
      inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
    border-radius: 6px;
    background-color: white;
    width: 175px !important;
    max-height: 325px !important;
    border: 0 !important;
    border-radius: 6px !important;

    &:hover {
      box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.1),
        0 2px 4px 0 rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
    }
  }

  .toolbarPopup {
    width: 275px !important;
    height: auto !important;
  }

  .rdw-dropdownoption-default {
    padding: 2px 10px !important;
  }

  .rdw-dropdown-optionwrapper {
    padding: 10px 0 !important;
  }

  .rdw-image-modal-url-input {
    width: 100% !important;
  }

  .rdw-image-modal-size-input {
    height: 35px !important;
    padding: 0 10px;
  }

  .rdw-block-dropdown {
    width: 100% !important;
    max-width: 135px;
  }
}

a,
.link {
  color: var(--blue);
  &:hover {
    color: var(--link);
  }
}

.tag {
  padding: 3px 12px;
  border-radius: 20px;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  span {
    font-size: 9px;
    font-weight: 500;
    color: var(--icon);
    margin: 0;
  }
}

.progressbar {
  background-color: var(--secondary);
  height: 4px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 4px;
  .progressbar__inner {
    background-color: var(--green);
    height: 100%;
    border-radius: 4px;
  }
}

.slimScroll {
  transition: all 0.3s ease-in;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    transition: all 0.3s ease-in;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: transparent;
    border-radius: 6px;
    transition: all 0.3s ease-in;
  }

  &:hover {
    &::-webkit-scrollbar-thumb:horizontal,
    &::-webkit-scrollbar-thumb {
      background-color: var(--border);
      transition: all 0.3s ease-in;
    }
  }

  &.transparent {
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar:horizontal {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 6px;
      transition: all 0.3s ease-in;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: transparent;
      border-radius: 6px;
      transition: all 0.3s ease-in;
    }

    &:hover {
      &::-webkit-scrollbar-thumb:horizontal,
      &::-webkit-scrollbar-thumb {
        background-color: var(--border);
        transition: all 0.3s ease-in;
      }
    }
  }
}

[data-rsbs-backdrop] {
  z-index: 1100;
}
[data-rsbs-overlay] {
  z-index: 1100;
}

a.ant-typography-expand {
  font-weight: 600;
  color: var(--link) !important;
}

.creator-tag {
  background-color: var(--text2);
  padding: 2px 6px;
  border-radius: 6px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 6px;
  color: var(--creator_label);
  font-size: 9px;
  font-weight: 500;
  width: max-content;
  height: max-content;
  align-self: center;
}

.ant-btn.fab-btn {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: var(--primary);
  color: var(--white);
  font-size: 24px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: 0;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  transition: all 0.3s ease-in;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.dropdownDivider {
  width: 100%;
  margin: 7.5px auto;
  border-top: 1px solid var(--border);
}

.inr {
  font-family: 'Roboto', sans-serif;
}

.new-chapter-card {
  position: absolute;
  bottom: -7px;
  left: -7px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 14px);
  height: 32px;
  background: #f2f2f5;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  &_wrapper {
    display: flex;
    align-items: center;
    p {
      font-size: 12px;
      font-weight: 500;
      margin: 0;
      margin-left: 3px;
    }
    b {
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.new-chapter-height {
  padding: 15px;
}
