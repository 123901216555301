.liked-by-users-modal {
  .ant-modal-body {
    overflow-y: hidden !important;
  }

  .liked-by-users-modal__card {
    background-color: var(--background);
    height: 100%;
    overflow-y: auto;

    &__users-list {
      &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--background);
        padding: 0;
        cursor: pointer;

        &__text-wrapper {
          margin-left: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 40px);
          padding: 12px 0px;

          .text {
            font-size: 14px;
            font-weight: 500;
            color: var(--text1);
            margin: 0;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
