.frequencyBandsContainer {
  display: grid;
  place-items: end;
  width: 97%;
  margin: 0 auto;
  gap: 1.5%;
  grid-template-columns: repeat(30, 1fr);
  height: 300px;
}

.frequencyBands {
  width: 100%;
  padding: 9px 0;
  transform: rotatex(180deg);
}
.audio-background {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.bgDark {
  opacity: 0;
  position: absolute;
  inset: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.25);
  transition: opacity 0.25s ease;
}
.bar {
  opacity: 0;
  position: absolute;
  bottom: 15px;
  left: 15px;
  margin: 0 auto;
  z-index: 3;
  user-select: none;
  width: calc(100% - 30px);
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  transition: opacity 0.25s ease;
  // background: linear-gradient(
  //   to top,
  //   hsla(0, 0%, 0%, 0.8),
  //   hsla(0, 0%, 0%, 0.45)
  // );
  background: rgba(0, 0, 0, 0.4);
  // position: relative;
  // z-index: 1;
  // &::after {
  //   content: '';
  //   position: absolute;
  //   display: block;
  //   height: 300%;
  //   width: 100%;
  //   bottom: 0;
  //   left: 0;
  //   z-index: -1;
  //   background: linear-gradient(
  //     180deg,
  //     rgba(25, 28, 33, 0) 55.21%,
  //     rgba(25, 28, 33, 0.6) 74.48%,
  //     #191c21 100%
  //   );
  // }

  .bar__time {
    color: white;
    font-weight: 500;
    letter-spacing: 0.025em;
    font-size: 16px;
    min-width: 100px;
    text-align: center;
    display: flex;
    white-space: nowrap;
    margin-top: 2px;
  }

  .bar__progress {
    flex: 1;
    border-radius: 5px;
    margin: 0 10px;
    height: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .bar__progress__knob {
      position: relative;
      height: 16px;
      width: 16px;
      border: 1.5px solid white;
      border-radius: 50%;
      background-color: #f88e00;
    }
  }
}

.ant-slider {
  width: 100%;
  .ant-slider-track {
    background-color: #f88e00 !important;
  }
  .ant-slider-handle {
    border: solid 2px #f88e00 !important;
  }
  &:focus,
  &:hover {
    .ant-slider-track {
      background-color: #f88e00 !important;
    }
    .ant-slider-handle {
      border: solid 2px #f88e00 !important;
      box-shadow: 0 0 0 5px rgba(255, 147, 24, 0.12) !important;
    }
  }
}

.audioComponent {
  width: 100%;
  position: relative;
  background-color: black;
  &.hover,
  &:hover {
    .mediaControls,
    .bar,
    .bgDark {
      opacity: 1;
    }
  }
  &.audio {
    background-color: white;
  }
  .audioGradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20%;
    background: linear-gradient(
      to top,
      white,
      rgba(255, 255, 255, 0.5) 60%,
      transparent
    );
  }
  &:fullscreen {
    .videoContainer {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      height: 100%;
      .videoWrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: fill;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      video {
        max-height: 100%;
      }
    }
  }
  button {
    background-color: transparent !important;
    border: none !important;
    outline: 0 !important;
    margin-left: 10px;
    padding: 0;
    color: white !important;
  }
}

.mediaControls {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: calc(100% - 47px);
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, calc(-50% - 24px));
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s ease;
  padding-top: 35px;
  button {
    background-color: transparent;
    border: none;
    padding: 0;
    color: white;
    border-radius: 50%;
    &:hover,
    &:focus {
      background-color: transparent;
      color: white;
      transform: scale(1.1);
      svg {
        text-shadow: 0 0 5px black;
      }
    }
  }
  .side {
    width: 30px;
    height: 30px;

    svg {
      height: 30px;
      width: 30px;
    }
  }
  .pausePlay {
    width: 50px;
    height: 50px;
    margin: 0 40px;
    svg {
      height: 50px;
      width: 50px;
    }
  }
}

.volumeBarWrapper {
  position: relative;
  button {
    background-color: transparent;
    border: none;
    padding: 0 5px;
    color: white;
    &:hover,
    &:focus {
      background-color: transparent;
      color: white;
      transform: scale(1.1);
    }
  }
  .bar.volume {
    position: absolute;
    z-index: 101;
    bottom: calc(100% + 12px);
    /* right: -100%; */
    left: 5%;
    -webkit-user-select: none;
    user-select: none;
    width: 10px;
    height: 170px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 12px;
    .ant-slider {
      position: absolute;
      top: 8%;
      left: 20%;
      margin: 0;
      height: 85%;
    }
  }
}

// video {
//   width: 100%;
//   display: block;
//   max-height: Max(60vh, 350px);
// }
.createPostModalImageWrapper video {
  max-height: Max(30vh, 250px);
}
.callBackList {
  padding: 0;
  margin: 0;
  overflow: hidden;
  .callBackListItem {
    padding: 5px 15px;
    list-style: none;
    &:is(:hover, :focus) {
      background-color: rgba(0, 0, 0, 0.2);
      & * {
        color: white;
      }
    }
  }
}
