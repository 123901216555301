$pkg: antd-img-crop;

.#{$pkg}-modal {
  .ant-modal-body {
    padding-bottom: 16px;
  }

  .#{$pkg}-container {
    position: relative;
    width: 100%;
    height: 40vh;
    margin-bottom: 16px;
  }

  .#{$pkg}-control {
    display: flex;
    align-items: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      padding: 0;
      font-style: normal;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      &[disabled] {
        cursor: default;
      }
    }

    &.zoom button {
      font-size: 18px;
    }

    &.rotate button {
      font-size: 16px;

      &:first-of-type {
        transform: rotate(-20deg);
      }

      &:last-of-type {
        transform: rotate(20deg);
      }
    }

    .ant-slider {
      flex: 1;
      margin: 0 8px;

      &-track {
        background-color: var(--primary) !important;
      }

      &-handle {
        border-color: var(--primary) !important;
      }
    }
  }

  max-height: 100%;

  .ant-modal-content {
    border-radius: 12px !important;
    box-shadow:
      0 12px 28px 0 rgba(0, 0, 0, 0.2),
      0 2px 4px 0 rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
    overflow: hidden;
  }

  .#{$pkg}-container {
    border-radius: 12px;
  }

  .ant-modal-header {
    position: relative;
    display: block;

    .ant-modal-title {
      font-size: 20px;
      font-weight: 600;
      color: var(--text2) !important;
      line-height: 1.4;
      margin: 0 !important;
    }
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 4px;
    padding: 16px;
    padding-top: 32px;
    border: none;
  }

  .ant-btn {
    border: none;
    box-shadow: none;
  }

  .ant-btn-default {
    display: flex;
    height: 36px !important;
    background-color: var(--border) !important;
    border-radius: 6px !important;
    padding: 0 16px !important;
    justify-content: center;
    align-items: center;
    color: var(--text2);
    column-gap: 0.25rem;

    > span {
      color: var(--text2);
      font-size: 14px;
      font-weight: 600;
    }

    &:hover,
    &:focus,
    &:active {
      filter: brightness(95%);
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;
      filter: grayscale(100%);
    }
  }

  .ant-btn-primary {
    height: 36px !important;
    background-color: var(--primary) !important;
    border-radius: 6px !important;
    padding: 0 16px !important;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // gap: 8px;

    span {
      color: var(--background);
      font-size: 14px;
      font-weight: 600;
    }

    &:hover,
    &:focus {
      filter: brightness(95%);
      svg {
        color: var(--background);
      }
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;
      filter: grayscale(100%);
      color: var(--background);
    }
  }
}
