.custom-apps__list {
  padding-top: 12px;
}
.custom-apps__item {
  background-color: var(--background);
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
  .custom-apps__item__content {
    width: 100%;
    .custom-apps__item__title {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: var(--text1);
    }
    .custom-apps__item__description {
      font-size: 13px;
      color: var(--text3);
      margin: 0;
      margin-top: 6px;
    }
  }
  .itemArrow {
    height: 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    color: var(--text2);
    svg {
      width: 16px;
    }
  }
}
