.shareOptionsWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 24px 0;
    max-width: 500px;
    margin: 0 auto;
    button {
        border: none;
        outline: 0;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        flex: 1;
        cursor: pointer;
        svg {
            width: 60px;
            height: 60px;
        }
    }
}
.shareBottomSheetContents {
    max-width: 500px;
    padding: 6px 12px;
    margin: 12px;
    border-radius: 6px;
    background: var(--gray_lighter);
    border: 1px solid var(--border);
    a {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    div {
        margin: 0;
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: 500;
        color: var(--text1);
    }
    @media (min-width: 500px) {
        margin: 12px auto;
    }
}
