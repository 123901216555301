.countryFlag {
  padding: 0 !important;

  > button {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    outline: 0;
  }

  .ReactFlagsSelect-module_selectOptions__3LNBJ {
    // margin: 0;
    width: 250px !important;
    border: 0;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // padding: 4px 0;
    font-variant: normal;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    max-height: 275px;
  }

  .ReactFlagsSelect-module_selectOptionWithlabel__2GpmM {
    padding: 5px 15px;
  }

  .ReactFlagsSelect-module_filterBox__3m8EU {
    padding: 15px 0 12px;
    input {
      border: 1px solid var(--border);
      border-radius: 4px;
      box-shadow: none;
      outline: 0;
      font-size: 14px;
      width: calc(100% - 30px);
      margin: 0 15px;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--gray_light);
        opacity: 1;
        /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--gray_light);
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--gray_light);
      }
    }
  }

  .ReactFlagsSelect-module_selectValue__152eS {
    padding-left: 2px;
  }
}

.loginBox {
  width: 100%;
  height: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    width: 325px;
  }
  @media screen and (min-width: 1280px) {
    width: 345px;
  }
  @media screen and (min-width: 1440px) {
    width: 375px;
  }
  @media screen and (min-width: 1920px) {
    width: 400px;
  }
}
.loginForm {
  .ant-form-item {
    margin-bottom: 16px;
  }
  .loginInput {
    border-color: var(--border);
    border-radius: 6px;
    font-size: 14px;
    padding-top: 11px;
    padding-bottom: 11px;
    max-height: 100%;
    color: var(--text1);
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--text2);
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--text2);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--text2);
    }
  }
}
.loginInputAddon {
  .ant-input-group-addon {
    background-color: white;
    border-color: var(--border);
    border-radius: 6px 0px 0px 6px;

    .countryFlag > button {
      padding: 8px 0;
    }
  }

  &.verify-box__login-input--addon .ant-input-group-addon {
    .countryFlag > button {
      padding: inherit;
    }
  }

  .ant-input {
    border-color: var(--border);
    border-radius: 6px;
    font-size: 14px;
    padding-top: 11px;
    padding-bottom: 11px;
    max-height: 100%;
    color: var(--text1);
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--text2);
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--text2);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--text2);
    }
  }
}

.loginFormSubmitBtn {
  border-radius: 6px !important;
  // margin-top: 12px;
  height: 45px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.loginFormFooter {
  text-align: center;
  color: var(--text4);
  font-size: 13px;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 8px;

  a {
    color: var(--blue);
    &:hover,
    &:focus {
      color: var(--blue);
    }
  }
}

.tmLoginLinkWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 16px;
  color: var(--text2);
  margin: 48px 0 96px;
  font-weight: 400;
  a {
    color: var(--primary);
    font-weight: 500;
    &:hover,
    &:focus {
      color: var(--primary_light);
    }
  }
}
.loginBoxFooter {
  margin-top: 24px;
  .loginCopyright {
    font-size: 12px;
    color: var(--text2);
    margin-bottom: 6px;
  }
  .loginv1BoxFooterLink {
    font-size: 11px;
    color: var(--text3);
  }
  .tmSignupAgree {
    text-align: center;
    font-size: 12px;
    color: var(--text2);
    span {
      font-weight: 500;
    }
    a {
      color: var(--primary);
      font-weight: 500;
      &:hover,
      &:focus {
        color: var(--primary_light);
      }
    }
  }
}

.loginHeader {
  margin-bottom: 24px;
  .loginHeaderLogo {
    display: inline-block;
    img {
      width: 100%;
      height: 100%;
      max-height: 56px;
      object-fit: contain;
    }
  }
  .loginHeaderTitle {
    font-size: 32px;
    line-height: 1.32;
    color: var(--text1);
    margin-top: 24px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .loginHeaderSubTitle {
    font-size: 16px;
    color: var(--text2);
    font-weight: 400;
    word-break: break-word;
  }
  @media screen and (max-width: 767px) {
    .loginHeaderTitle {
      font-size: 26px;
    }
  }
  .adm-list-body {
    border-top: none;
  }
}

.emailPhoneInput {
  display: flex;
  align-items: center;
  border: 1px solid #cdd1d5;
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 0;
  .flagSelector {
    border-right: 1px solid #cdd1d5;
    padding: 0 1rem;
    height: 2.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div:nth-child(2) {
    padding-left: 0.5rem;
    margin-left: 0.5rem;
  }
}

.loginOTPContainer {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 12px;
  width: 100%;
  max-width: 320px;

  .loginOTPInput {
    width: 100% !important;
    border: 1px solid var(--secondary);
    color: var(--text1);
    height: 45px;
    border-radius: 6px;

    &:focus,
    &:focus-visible {
      outline-color: var(--primary);
    }
  }

  // .loginOTPInputFocus {
  //   outline-color: var(--primary);
  // }
}

.loginFormWarning {
  background-color: #ffc10720;
  padding: 12px 16px;
  margin-top: 16px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;

  svg {
    flex-shrink: 0;
  }

  .ant-typography {
    color: #5a0f00;
  }

  h5 {
    font-size: 13px;
    font-weight: 500;
  }

  a {
    font-weight: 600;
    font-size: 14px;
    color: #5a0f00;
  }
}
