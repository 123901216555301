.vdoCipherPlaceholder {
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    > button {
        -webkit-appearance: none;
        border: 0;
        background: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%);
        border-radius: 64px;
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .playicon {
            border-style: solid;
            border-width: 16px 0px 16px 26px;
            border-color: transparent transparent transparent white;
            margin-left: 7px;
        }
    }
}
