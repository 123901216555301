.mango__selection__modal {
  [data-rsbs-header] {
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .mango__selection__modal__title {
    font-size: 16px;
    font-weight: 600;
    color: var(--text1);
    margin-bottom: 0px;
  }
  .mango__selection__modal__description {
    font-size: 14px;
    font-weight: 400;
    color: var(--text2);
    margin-bottom: 0px;
  }

  .mango__selection__modal__content__wrapper {
    padding: 12px;
  }

  .mangoSelectorCard {
    margin-top: 12px;
    border: 1px solid var(--border);
    border-radius: 4px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    .mangoSelectorCard__name {
      font-size: 14px;
      font-weight: 400;
      color: var(--text1);
    }
    .mangoSelectorCard__button {
      height: 24px;
    }
  }
}
