.platform-crashed__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;

  .darkBtn {
    background-color: #000 !important;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
