.notificationItemWrapper {
    border: 0px solid var(--border);
    margin: 0 12px;
    padding: 12px 0;

    .notification {
        display: flex;

        .ant-avatar {
            flex: 0 0 40px;
            color: var(--primary);
            background-color: var(--primary_light);
        }

        img {
            width: 45px;
            height: 45px;
            border-radius: 50%;

            @media (max-width: 450px) {
                width: 35px;
                height: 35px;
            }
        }

        .notificationDetails {
            padding-left: 15px;
            display: flex;
            flex-direction: column;

            .siteText {
                color: var(--text1);
                font-weight: 500;
                margin-bottom: 4px;
                font-size: 15px;
            }

            .notificationTime {
                font-size: 12px;
                color: var(--text2);
            }
        }

        &.hasSeen {
            opacity: 0.5;

            .ant-avatar {
                filter: grayscale(100%);
            }

            .notificationDetails {
                .siteText {
                    color: var(--text3);
                }
            }
        }
    }
}
