.creatorProfile {
    .creatorProfile__image__container {
        height: 100px;
        padding: 60px;
        margin-bottom: 84px;
        background-color: #000;
        display: flex;
        justify-content: center;
        position: relative;
        .creatorProfile__image--blur {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.5;
        }
        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            background-color: #00000030;
        }
        .ant-avatar {
            position: relative;
            z-index: 2;
            border: 5px solid var(--background);
            box-shadow: 0 0 6px rgb(0 0 0 / 10%);
        }
    }
    .creatorProfile__name {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 8px;
        padding: 0 12px;
        text-align: center;
        color: var(--text1);
    }
    .creatorProfile__subscribers {
        font-size: 14px;
        font-weight: 500;
        margin: 0 !important;
        padding: 0 12px;
        text-align: center;
        color: var(--text2);
    }
    .creatorProfile__mangoList {
        padding: 0 12px;
        text-align: left;
        max-width: 500px;
        margin: 0 auto;
        .creatorProfile__mangoList__title {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 4px;
            color: var(--text1);
        }
        .creatorProfile__mangoList__description {
            font-size: 14px;
            font-weight: 500;
            margin: 0 !important;
            color: var(--text3);
        }
    }
    .creatorProfile__noMango__container {
        padding: 12px;
        .creatorProfile__noMango__title {
            font-size: 20px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 4px;
            color: var(--text1);
            text-align: center;
        }
        .creatorProfile__noMango__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: var(--gray_light);
            border: 1px solid var(--border);
            padding: 36px 12px;
            border-radius: 12px;
            max-width: 600px;
            margin: 0 auto;
            .creatorProfile__noMango__icon {
                margin-bottom: 18px;
            }
            .creatorProfile__noMango__description {
                font-size: 14px;
                font-weight: 500;
                margin: 0 !important;
                color: var(--text3);
            }
            .notifyInput {
                border-radius: 6px;
                height: 44px;
            }
        }
    }

    .creatorProfileDetailsWrapper {
        position: relative;
        display: block;
        border-radius: 6px;
    }

    .creatorProfileDetails {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-top: 12px;
    }

    .creatorProfileDetails > p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-top: 12px;
    }

    .creatorProfile__about__title {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 12px;
        color: var(--text1);
    }
    .creatorProfile__about__wrapper {
        max-width: 500px;
        margin: 0 auto;
        padding: 12px;
    }
    .becomeSubscriber {
        max-width: 500px;
        padding: 12px;
        background-color: var(--primary_light);
        border-radius: 8px;
        margin: 0 12px 12px;
        .becomeSubscriberTitle {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 12px;
            color: var(--text1);
        }
        .pointer {
            display: flex;
            align-items: center;
            svg {
                width: 18px;
                height: 18px;
            }
            .pointerTitle {
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                margin-left: 12px;
                color: var(--text3);
            }
            &:not(:last-child) {
                margin-bottom: 6px;
            }
        }
        @media screen and (min-width: 524px) {
            margin: 0 auto 12px;
        }
    }
    .creatorProfileSocialActions {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 12px 0 0;
        gap: 8px;
    }
}
