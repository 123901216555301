.error-boundary__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;

  .darkBtn {
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--background) !important;
    background-color: #000 !important;

    & > span {
      color: currentColor;
    }
  }
}
