.videoProgress {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  background-color: #000;

  &.videoProgressPlayBtn {
    background-color: transparent;
  }

  .videoProgressTitle {
    // font-size: 1.25rem;
    font-size: 1rem;
    font-weight: 600;
    color: white !important;
    margin-bottom: 10px;
    text-align: center;
  }

  .videoProgressText {
    color: rgba($color: white, $alpha: 0.8);
    text-align: center;
  }
}

.iconPlayer {
  cursor: pointer;
  height: 38px;
  width: 35px;
  margin-right: 4px;
  margin-left: 4px;
  border: none;
  background: none;
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(92deg) brightness(104%)
    contrast(101%);
  padding: 0;
}

#video-controls {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'progressbar'
    'controls-main';
  position: absolute;
  bottom: 4px;
  width: 100%;
  padding-bottom: 7px;
  box-sizing: border-box;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  transition: opacity 0.3s;
  background: #0000007d;

  div[role='button'] {
    outline: none;
  }
}

.playerContainer:hover #video-controls {
  opacity: 0.9;
}

.progress {
  grid-area: progressbar;
  display: grid;
  grid-template-columns: 14% 72% 14%;
  grid-template-rows: 1fr;
  grid-template-areas: 'ctime seek ttime';
  cursor: pointer;
  width: 100%;
  margin: auto;
  border-radius: 6px;
  left: 4px;
  bottom: 100%;
  transition: height 0.1s ease-in-out;
}
.current-time {
  grid-area: ctime;
  color: #ffffff;
  margin: auto;
}
.total-time {
  grid-area: ttime;
  color: #ffffff;
  margin: auto;
}
#seek {
  cursor: pointer;
  grid-area: seek;
  width: 100%;
  outline: none;
  height: 5px;
  margin: auto;
}

.controls-main {
  grid-area: controls-main;
  width: 100%;
  margin: auto;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'controls-left play controls-right';
  justify-content: space-between;
}

.controls-left {
  grid-area: controls-left;
  align-items: center;
  display: flex;
  padding-left: 14px;
}
.controls-right {
  grid-area: controls-right;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 14px;
}

#center_p {
  display: flex;
  grid-area: play;
  cursor: pointer;
  text-align: center;
  justify-content: center;
}

.volume {
  display: flex;
  align-items: center;
}

#volumeSeek {
  cursor: pointer;
  border-radius: 6px;
  width: 80px;
  height: 5px;
  margin: auto;
}
#unlock {
  position: absolute;
  left: 14px;
  z-index: 1;
  top: 14px;
  display: none;
}

#superplay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

#speed-list {
  cursor: pointer;
  display: none;
  background: #131212e3 !important;
  color: #fff !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px;
  text-align: center;
  position: absolute;
  // top: 0;
  right: 0;
  bottom: 80px;
}
.speed-list p {
  color: var(--main) !important;
  padding: 5px;
  cursor: default;
}
.speed-list p:hover {
  color: var(--accent) !important;
  font-weight: bold;
}

.fullscreen {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .iconPlayer {
    cursor: pointer;
    height: 38px;
    width: 30px;
    margin-right: 4px;
    margin-left: 4px;
    border: none;
    padding: 0;
    background: none;
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(92deg)
      brightness(104%) contrast(101%);
  }

  #video-controls {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'progressbar'
      'controls-main';
    position: absolute;
    bottom: 4px;
    width: 100%;
    padding-bottom: 0px;
    box-sizing: border-box;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    transition: opacity 0.3s;
    background: #0000007d;

    div[role='button'] {
      outline: none;
    }
  }

  .playerContainer:hover #video-controls {
    opacity: 0.9;
  }

  .progress {
    grid-area: progressbar;
    display: grid;
    grid-template-columns: 14% 72% 14%;
    grid-template-rows: 1fr;
    grid-template-areas: 'ctime seek ttime';
    cursor: pointer;
    width: 100%;
    margin: auto;
    border-radius: 6px;
    left: 4px;
    bottom: 100%;
    transition: height 0.1s ease-in-out;
  }
  .current-time {
    grid-area: ctime;
    color: #ffffff;
    margin: auto;
  }
  .total-time {
    grid-area: ttime;
    color: #ffffff;
    margin: auto;
  }
  #seek {
    cursor: pointer;
    grid-area: seek;
    width: 100%;
    outline: none;
    height: 5px;
    margin: auto;
  }

  .controls-main {
    grid-area: controls-main;
    width: 100%;
    margin: auto;
    height: 100%;
    display: grid;
    grid-template-columns: 40% 20% 40%;
    grid-template-rows: 1fr;
    grid-template-areas: 'controls-left play controls-right';
    justify-content: space-between;
  }

  .controls-left {
    grid-area: controls-left;
    align-items: center;
    display: flex;
    padding-left: 14px;
  }
  .controls-right {
    grid-area: controls-right;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-right: 14px;
  }

  #play {
    grid-area: play;
    cursor: pointer;
    text-align: center;
  }

  .volume {
    display: flex;
    align-items: center;
  }

  #volumeSeek {
    cursor: pointer;
    border-radius: 6px;
    width: 80px;
    height: 5px;
    margin: auto;
  }
  #unlock {
    position: absolute;
    left: 14px;
    z-index: 1;
    top: 14px;
    display: none;
  }

  #superplay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }

  #speed-list {
    cursor: pointer;
    display: none;
    background: #131212e3;
    color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    min-width: 160px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
  }
  .speed-list p {
    color: var(--main);
    padding: 5px;
    cursor: default;
  }
  .speed-list p:hover {
    color: var(--accent);
    font-weight: bold;
  }

  .fullscreen {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
}
.playerContainer {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background-color: #000;
}

.activeSpeed {
  color: #0094ff;
  font-weight: bold;
}

.player-wrapper-drm-custom {
  position: relative;
  z-index: 1;
  width: 100%;
  // padding-top: 75%;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.player-wrapper-drm {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 75%;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.video-js.vjs-fluid {
  padding-top: 75%;
  inset: 0;
  position: absolute;
}

.bmpui-ui-rewindbutton {
  background-image: url(./BitmovinPlayer/rewind_button.svg);
  background-size: contain;
}

.bmpui-ui-forwardbutton {
  background-image: url(./BitmovinPlayer/forward_button.svg);
  background-size: contain;
}

.bmpui-ui-rewindbutton:hover,
.bmpui-ui-forwardbutton:hover {
  -webkit-filter: drop-shadow(0 0 1px #fff);
  filter: drop-shadow(0 0 1px #fff);
}
