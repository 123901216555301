.account-warning-card {
  background-color: var(--background);
  padding: 12px;
  border: 1px solid var(--danger);
  .warning__title {
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    background-color: var(--danger);
    color: var(--background);
    width: max-content;
    padding: 5px 10px;
    border-radius: 15px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  .divider {
    margin: 12px 0;
    background-color: var(--border);
    height: 1px;
    width: 100%;
  }
}
.account-warning-item {
  .account-warning-item__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--text1);
  }
  .account-warning-item__description {
    font-size: 13px;
    font-weight: 400;
    color: var(--text3);
    margin-bottom: 12px;
    b {
      font-weight: 500;
      color: var(--text1);
    }
  }
  .account-warning-item__button {
    font-size: 15px;
    font-weight: 500;
    color: var(--text1);
    text-decoration: underline;
    cursor: pointer;
  }
}
