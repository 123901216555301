.errorBox {
  padding: 6px 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid var(--warning);

  .errorText {
    color: var(--text2);
    font-size: 14px;
    font-weight: 400;
  }
}
