.bookslot__container {
  .scroll__container {
    padding-block: 12px;
    & > *:not(.divider-border) {
      padding-inline: 12px;
    }

    .mb-0 {
      margin-bottom: 0;
    }

    .event-title {
      font-size: calc(1rem * 16 / var(--font-size-base));
      color: var(--text1);
      font-weight: var(--font-weight-semibold);
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 8px;
    }

    .subtitle {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #616161;
      font-weight: 500;
      margin-block: 8px;
      span {
        white-space: nowrap;
      }
    }

    a.ant-typography-expand {
      color: var(--link);
    }

    .label {
      font-weight: 500;
      font-size: 16px;
      margin: 0;
    }

    .month-title {
      font-weight: 400;
      color: var(--text3);
    }

    .arrow-btn {
      border-color: transparent;
    }

    .ant-picker-calendar .ant-picker-panel {
      border-top-color: transparent;
    }

    .custom-calendar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 6;
    }

    .date-active-dot {
      height: 6px;
      width: 6px;
      border-radius: 3px;
      background-color: var(--link);
      margin: 2px auto 0;
    }

    .slots-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      //   margin-bottom: 12px;

      &__item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 6px;
        border-radius: 4px;
        border: 1px solid var(--border);
        color: var(--text3);
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        transition:
          color 0.2s ease,
          border-color 0.2s ease;

        &.selected {
          border-color: var(--primary);
          color: var(--primary);
        }
      }
    }
  }

  .bookslot__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-top: 1px solid var(--border);
  }
}
