@import '../../sass/mixins';

.bottom--navbar__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 6px;
  height: 65px;
  border-top: 1px solid var(--border);
  background-color: var(--background);
  position: relative;
  z-index: 1;
  a {
    width: 100%;
    padding: 0px 6px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--icon);
    text-decoration: none;
    .tab-icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      //   padding: 4px;
      //   border-radius: 30px;
      svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }
      h5 {
        margin: 0;
        margin-top: 4px;
        @include font-size(9);
        color: var(--accent);
        font-weight: var(--font-weight-regular);
        max-width: 12ch;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        // margin-left: 0;
        // max-width: 0;
        // overflow: hidden;
        transition-property: max-width, margin-left;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
      }
    }

    .profile__icon {
      .ant-avatar {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 2px solid var(--secondary);
        object-fit: cover;
        padding: 2px;
        > * {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      h5 {
        margin: 0;
        margin-top: 2px;
        @include font-size(9);
        color: var(--accent);
        font-weight: var(--font-weight-regular);
        // margin-left: 0;
        // max-width: 0;
        // overflow: hidden;
        transition-property: max-width, margin-left;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
      }
    }

    &.active {
      color: var(--primary);
      // .tab-icon {
      //   // background-color: var(--primary_light);
      //   // padding: 4px 16px 4px 8px;
      //   h5 {
      //       max-width: 100vw;
      //       margin-left: 6px;
      //   }
      // }
      .profile__icon {
        .ant-avatar {
          border: 2px solid var(--primary);
        }
      }
    }
    &.disabled {
      color: var(--secondary);
      cursor: not-allowed;
    }
  }
  .ant-badge {
    position: absolute;
    top: 0px;
    left: 32px;
  }
}
