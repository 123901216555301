.notificationSettingsWrapperOuter {
    .settingBlockTitle {
        font-size: 20px;
        color: var(--text1);
        font-weight: 600;
        margin-bottom: 8px;
    }
}

.notificationSettingsWrapper {
    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 24px;
    .notificationSettingsWrapperHeader {
        padding: 12px 7px 8px;
        transition: background-color 0.2s, border-radius 0.2s;
        background-color: var(--tertiary);
        border-radius: 5px;
        cursor: pointer;
        .notificationSettingsWrapperHeaderInner {
            display: flex;
            svg {
                width: 34px;
                height: 34px;
                flex-shrink: 0;
            }
            .notificationTitleWrapper {
                padding-left: 15px;
                align-self: center;
                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    color: var(--text1);
                    margin: 0;
                    line-height: 1;
                }
                span {
                    font-size: 12px;
                    color: var(--text3);
                    line-height: 1;
                }
            }
        }
    }
    .notificationSettingWrapperBody {
        padding: 8px 7px 0;
        .details {
            font-size: 13px;
            color: var(--text2);
            font-weight: 500;
        }
        h6 {
            font-size: 14px;
            font-weight: 600;
            color: var(--text1);
            margin: 0;
        }
        .settingList {
            margin-top: 8px;
            .settingListItem {
                padding: 10px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .settingsTitleWrapper {
                    font-size: 14px;
                    color: var(--text2);
                    place-items: center;
                    display: block;
                }
            }
            > .settingListItem + .settingListItem {
                border-top: 1px solid var(--border);
            }
        }
    }
    > div {
        padding: 8px;
    }
    > div + div {
        margin-top: 8px;
        border-top: 1px solid var(--border);
    }
}
