.askAQuestionModal {
    padding-bottom: 0 !important;
    max-height: 100%;

    .ant-modal-content {
        border-radius: 12px !important;
        box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
            inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
    }

    .ant-modal-header {
        border-radius: 12px 12px 0 0;
        padding: 0px;
        border: none;
        .default-header {
            border-radius: 12px 12px 0 0;
        }
    }

    .ant-modal-body {
        padding: 12px;
        border-radius: 12px 12px 0 0;
        overflow: hidden;
        height: 100%;
        max-height: calc(var(--app-height) - 240px);
        transition: all 0.3s ease-in;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar-track {
            // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 0px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            transition: all 0.3s ease-in;
        }

        &::-webkit-scrollbar-thumb:horizontal {
            background-color: transparent;
            transition: all 0.3s ease-in;
        }

        &:hover {
            &::-webkit-scrollbar-thumb:horizontal,
            &::-webkit-scrollbar-thumb {
                background-color: transparent;
                transition: all 0.3s ease-in;
            }
        }
    }

    .profileCard {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 24px;
        .profileCard__details {
            width: 100%;
            padding-left: 8px;
            .profileCard__name {
                font-size: 16px;
                font-weight: 500;
                color: var(--text1);
                margin: 0;
            }
            .profileCard__role {
                font-size: 12px;
                font-weight: 500;
                color: var(--text4);
                margin: 0;
            }
        }
    }

    .uploadWrapper {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, calc(calc(100% / 4) - 6px));
        gap: 8px;

        .uploadWrapper__imageWrapper,
        .uploadWrapper__addIcon {
            background-color: var(--tertiary);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            position: relative;
            width: 100%;
            aspect-ratio: 4/3;
        }

        .uploadWrapper__imageWrapper {
            border: 1px solid var(--border);
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
            .uploadWrapper__imageWrapper__removeIcon {
                position: absolute;
                top: -5px;
                right: -5px;
                svg {
                    height: 20px;
                    width: 20px;
                }
            }
        }
        .ant-upload.ant-upload-select-picture-card {
            margin: 0;
            border-radius: 100%;
            border: 0;
            display: block;
            width: 100%;
            height: max-content;

            > .ant-upload {
                padding: 0px;
            }
        }
        .uploadWrapper__addIcon {
            svg {
                width: 24px;
                height: 24px;
            }
        }
        #qnaFileUpload {
            display: none;
        }
    }
}
