.messageList {
  display: flex;
  overflow-x: hidden;
  contain: paint;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  overflow-y: auto;
  flex-direction: column;
  flex-grow: 1;
  // scrollbar-width: none;

  .messageList__container {
    position: relative;
    height: max-content;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f6 !important;
    z-index: 0;
    flex-grow: 1;

    &__bg-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.04;
    }
  }

  .messageList__spacer {
    align-self: stretch;
    display: flex;
    max-width: 100%;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
  }

  .messageList__dateContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .messageList__date {
      text-align: center;
      text-transform: uppercase;
      color: var(--text3);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.025em;
      margin: 0;
    }
  }
}

.subscribersNotAllowed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  background-color: var(--background);
  // background-color: var(--gray_lighter);
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);

  .subscribersNotAllowed__text {
    font-size: 12px;
    font-weight: 400;
    color: var(--text3);
    margin: 0 12px 0 8px;
  }

  .siteBtnLink > span {
    font-size: 14px;
  }
}
