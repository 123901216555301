.leaderboard__container {
  $light-grey: #fafafa;

  .points-info-action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--tertiary);

    svg {
      width: 20px;
      height: 20px;
      display: block;
      color: var(--icon);
    }
  }

  .scroll__container {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    padding: 12px;
    background-color: var(--background);
  }

  .tabs-wrapper {
    padding: 0 12px;
    background-color: var(--background);
    margin-bottom: 6px;

    .tab__selector {
      //   height: 40px;
      padding: 0;
      border-radius: 50px;
      border: none;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      //   background-color: $light-grey;

      &__item {
        width: 100%;
        margin-block: 12px;
      }

      //   &__item {
      //     flex-grow: 1;
      //     text-align: center;
      //     border: none !important;
      //     box-shadow: none !important;
      //     border-radius: 50px;
      //     background-color: $light-grey;
      //     margin: 0;

      //     .tab__selector__item__text {
      //       color: var(--text1);
      //     }

      //     &::before {
      //       display: none;
      //     }

      //     &.selected {
      //       .tab__selector__item__text {
      //         color: var(--background);
      //       }
      //       background: #3f3f3f !important;
      //     }
      //   }
    }
  }

  .top-leaderboard {
    // padding: 12px;
    padding-top: 110px;
    // background-color: var(--background);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: end;
    justify-content: center;
    gap: 12px;
    margin-bottom: 16px;

    .winner-card {
      display: flex;
      flex-direction: column;
      justify-content: end;
      gap: 8px;
      text-align: center;
      padding: 12px 6px;
      padding-top: 55px;
      background-color: var(--border);
      border-radius: 36px 36px 12px 12px;
      position: relative;

      .points-pill {
        margin-inline: auto;
      }

      &.highlight {
        background-color: var(--primary_light);
        padding-top: 80px;
        border-radius: 75px 75px 12px 12px;
        .avatar {
          top: -75px;
          width: 128px;
          height: 128px;
          background-color: var(--primary_light);

          &__icon {
            border-radius: 50%;
            border: 2px solid var(--primary_light);
            outline: 4px solid var(--primary);
          }

          .medal {
            bottom: -12px;
            // width: 48px;
            height: 48px;
          }

          .crown {
            width: 48px;
            // height: 48px;
            position: absolute;
            left: 50%;
            top: -28px;
            transform: translateX(-50%);
          }
        }
      }

      .avatar {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        padding: 12px;
        background-color: var(--border);
        position: absolute;
        top: -60px;
        left: 50%;
        transform: translateX(-50%);

        &__icon {
          border-radius: 50%;
          border: 2px solid var(--border);
          outline: 4px solid var(--text3);
        }

        .medal {
          //   width: 36px;
          height: 36px;
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .details {
        .name {
          font-size: 14px;
          font-weight: 600;
          color: var(--text1);
          margin-bottom: 8px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 44px;
        }
      }
    }
  }

  //  {
  //   padding-block: 12px;
  //   padding-inline: 12px;
  //   background-color: var(--background);

  .leaderboard-list__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 8px;
    border: 1px solid var(--border);
    border-radius: 50px;
    transition: border-color 300ms ease;
    margin-bottom: 12px;

    &.accent {
      padding-left: 16px;
      background-color: var(--primary_light);
      border-color: var(--primary_light);
      color: var(--primary);

      .rank {
        margin-right: 0;
        background-color: var(--primary);
      }
    }

    .rank {
      display: flex;
      min-width: 30px;
      height: 30px;
      padding-inline: 8px;
      margin-right: 4px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--background);
      background-color: var(--icon);
      border-radius: 50px;
    }

    .name {
      flex-grow: 1;
      flex-basis: 33.33%;
      font-size: 14px;
      font-weight: 600;
      // margin-bottom: 8px;
      // max-width: 85px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover {
      // box-shadow: none;
      border-color: var(--primary);

      .rank {
        background-color: var(--primary);
      }
    }
  }
}
// }
