.messages__container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .messages__content {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    background-color: var(--background);
  }
}

.ant-btn.fab-btn.fab-btn-message {
  bottom: 81px;
  right: 16px;
}
