.messageInputBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  position: relative;
  .uploadWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, calc(calc(100% / 4) - 9px));
    gap: 12px;
    margin-bottom: 12px;

    .uploadWrapper__imageWrapper {
      background-color: var(--tertiary);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      position: relative;
      width: 100%;
      aspect-ratio: 4/3;
      border: 1px solid var(--border);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      .uploadWrapper__imageWrapper__removeIcon {
        position: absolute;
        top: -5px;
        right: -5px;
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  .messageInputBox__content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    .messageInputBox__content__inputWrapper {
      display: flex;
      align-items: flex-end;
      width: 100%;
      background-color: var(--gray_lighter);
      padding: 2.5px;
      padding-right: 12px;
      border-radius: 20px;
      margin-right: 12px;
      .messageInputBox__content__inputWrapper__addIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        background-color: var(--primary);
        border-radius: 50%;
        flex-shrink: 0;
        padding: 0;
        border: none;
        margin-right: 8px;
      }

      #repliesFileUpload {
        display: none;
      }
      .messageInputBox__content__inputWrapper__input {
        width: 100%;
        border: none;
        background-color: transparent;
        font-size: 16px;
        color: var(--text1);
        font-weight: 400;
        line-height: 25px;
        min-height: 25px;
        padding: 5px 0;
        outline: 0;
        resize: none;
        max-height: 100px;
        overflow-y: auto;
        direction: ltr;
        text-align: left;
        word-break: break-word;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--text3) !important;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--text3) !important;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--text3) !important;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--text3) !important;
        }
      }
    }
    .messageInputBox__content__sendIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: var(--primary);
      border-radius: 50%;
      flex-shrink: 0;
      padding: 0;
      border: none;
      .ant-spin-dot-item {
        background-color: var(--background);
      }
    }
  }
}
