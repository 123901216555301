.points-table {
  margin: 12px;
  background-color: var(--tertiary);
  border-radius: 6px;

  &__item {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    border-bottom: 1px solid var(--border);

    &:last-child {
      border-bottom: none;
    }

    &__title {
      font-size: 13px;
    }

    &__points {
      font-size: 13px;
      font-weight: 500;
      color: var(--text3);
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
}
