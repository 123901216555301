.user-profile__container {
  flex: 1;
  background-color: var(--page_bg);

  .user-profile__content {
    height: 100%;
    width: 100%;
    padding: 0;
    overflow-y: auto;
    background-color: var(--page_bg);

    .user-profile-card {
      padding: 12px;
      background-color: var(--background);
      height: max-content;

      .name-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;

        .title {
          font-size: 18px;
          margin-bottom: 0;
        }

        &__content {
          flex: 1;
        }

        .creator-tag {
          margin-left: 0;
          margin-top: 4px;
        }
      }

      .gamification-details {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-block: 16px;

        .points-pill {
          font-size: 14px;
          padding: 4px 8px;
          // color: var(--primary);
          font-weight: 500;
        }
      }

      .bio-description {
        color: var(--text3);
        font-size: 14px;
      }

      .join-date {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 12px;
        color: var(--text3);
        font-weight: 500;
      }

      .socials-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 12px;
      }

      .blocked-message {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        font-size: 12px;
        color: var(--danger);
        margin-top: 12px;
        padding: 12px;
        border-radius: 6px;
        background-color: #fde9ec;
      }

      .action-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 24px;

        .grayBtn:not(.menuBtn) {
          flex: 1;
        }

        .primaryBtn {
          background-color: var(--primary) !important;
          span {
            color: #fff !important;
          }
        }

        .menuBtn {
          margin-left: auto;
          width: 50px !important;
        }
      }
    }
  }
}

.subscriptions__container {
  .subscriptions__content {
    background-color: var(--background);

    .scroll__container {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 12px;

      .mango__item {
        margin-bottom: 12px;
        padding: 12px;
        border: 1px solid var(--border);
        border-radius: 6px;

        .title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 12px;
          color: var(--text2);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
