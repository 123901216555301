.settingsItem {
  padding: 12px;
  background-color: var(--background);
  transition: all 0.15s ease;
  border-radius: 4px;
  cursor: pointer;
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  .settingsItem__inner {
    display: flex;
    flex: 1;
    .icon {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      img,
      svg {
        height: 20px;
        width: 20px;
      }
    }
    .details {
      padding-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .title {
      color: var(--text1);
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }
    .subtitle {
      margin: 0;
      margin-top: 2px;
      font-weight: 400;
      font-size: 12px;
      color: var(--text2);
      line-height: 1.25;
      -webkit-font-smoothing: antialiased;
    }
  }
  .settingsItem__additionalInfo {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
