.create-post-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;

  .post-tag-item {
    padding: 4px 8px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 500;
    color: var(--text2);
    // color: var(--background);
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    background: var(--tertiary);
    // background-color: var(--primary);

    .post-tag-item-text {
      color: var(--text2);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    svg {
      flex-shrink: 0;
    }
  }
}

.createpost__container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: var(--app-height);
  .scroll__container {
    padding: 12px;
    height: 100%;
    overflow: auto;
  }
  .createpost__description__container {
    margin: 24px 0;
    .createpost__description {
      width: 100%;
      border: none;
      outline: none;
      resize: none;
      font-size: 16px;
      font-weight: 400;
      color: var(--text1);
      background: none;
      padding: 0px;
      border-radius: 0px;
      box-shadow: none !important;
    }
  }

  .createpost__repost-card {
    // padding: 12px;
    border: 1px solid var(--border);
    border-radius: 6px;
    overflow: hidden;
  }

  .createpost__attachment {
    position: relative;
    .createpost__attachment__actions {
      display: flex;
      position: absolute;
      top: 12px;
      left: 12px;
      flex-direction: row;
      gap: 6px;
      z-index: 100;
      .createpost__attachment__actions__button {
        flex-shrink: 0;
        height: 40px;
        width: 40px;
        background: var(--tertiary);
        color: var(--text2);
        border-radius: 50%;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 6px;
        &:hover {
          background: var(--tertiary);
        }
      }
    }
  }

  .createpost__attachment__image__edit__info {
    display: flex;
    align-items: center;
    background-color: var(--primary_light);
    padding: 8px;
    margin-top: 12px;
    border-radius: 6px;
    color: var(--primary);
    gap: 6px;
    font-size: 10px;
  }

  .createpost__attachment__image {
    aspect-ratio: 12 / 9;
    background-color: #000;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .createpost__attachment__video {
    width: 100%;
    aspect-ratio: 12/9;
    position: relative;
    .player-wrapper-drm-custom {
      min-height: calc(100vw * 9 / 16);
    }
  }
}

.create-post-header {
  display: flex;
  gap: 12px;
  align-items: center;
  .create-post-header-details {
    width: calc(100vw - 91px);
    h5 {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 600;
      color: var(--text1);
    }
    .create-post-header-details-mango {
      display: flex;
      .create-post-header-details-mango-button {
        padding: 3px 6px;
        border-radius: 6px;
        color: var(--text2);
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--tertiary);
        gap: 6px;
        max-width: 100%;
        span {
          font-size: 10px;
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

.loading__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.createpost__attachment__container {
  padding: 12px;
  border-top: 1px solid var(--border);
  &.borderless {
    border: none;
  }
  .createpost__attachment__title {
    font-size: 12px;
    font-weight: 500;
    color: var(--text3);
    margin-bottom: 12px;
  }
  .createpost__attachment__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    .createpost__attachment__option {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      .createpost__attachment__option__icon {
        height: 50px;
        width: 50px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--tertiary);
        border-radius: 50%;
        border: none;
        outline: 0;
        cursor: pointer;
        padding: 0;
        color: var(--icon);
      }
      .createpost__attachment__option__label {
        font-size: 10px;
        font-weight: 400;
        color: var(--text4);
      }
    }
  }
}

.createpoll {
  .createpoll__question {
    margin: 24px 0;
  }
  .createpoll__option {
    .createpoll__option__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;

      .createpoll__option__label {
        font-size: 13px;
        font-weight: 500;
        color: var(--text3);
      }
      button {
        padding: 0;
        color: var(--danger);
        font-weight: 600;
        height: max-content;
      }
    }
    .ant-input-affix-wrapper {
      padding: 8px 12px;
      border: 1px solid var(--text4);
      border-radius: 8px;
      input {
        font-size: 16px;
        font-weight: 400;
        padding: 0;
        margin: 0;
        width: 100%;
        color: var(--text1);
      }
      .ant-input-show-count-suffix {
        font-size: 13px;
        line-height: 13px;
        font-weight: 500;
        color: var(--text2);
        margin: 0;
      }
    }
  }
  .createpoll_add-button {
    margin-top: 18px;
    padding: 8px 18px;
    gap: 8px;
    span {
      font-weight: 500;
      font-size: 12px;
    }
  }
}
