.image-attachment {
    display: grid;
    max-width: 500px;
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 8px), 1fr));
    width: 100%;
    background-color: var(--tertiary);
    border-radius: 6px;
    gap: 8px;
    cursor: zoom-in;
    img,
    .moreImage__wrapper,
    .more-text {
        width: 100%;
        object-fit: cover;
        background-color: var(--tertiary);
        border: none;
        aspect-ratio: 16/9;
        border-radius: 6px;
    }
    .moreImage__wrapper {
        position: relative;
    }
    .more-text {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00000070;
        position: absolute;
        span {
            font-size: 18px;
            color: #fff;
            font-weight: 500;
            margin: 0;
        }
    }
}
