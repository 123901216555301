.tmLoginWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  min-height: var(--app-height);

  .tmLoginLeft {
    background-color: var(--background);
    padding: 24px 24px 20px;
    align-items: stretch;
    border: 1px solid var(--primary);
    flex-shrink: 0;
    margin: 0 auto;
    border-radius: 24px;
    box-shadow: 6px 6px var(--primary_light);
  }
  flex-direction: column;
  background-color: var(--primary);
  background-image: linear-gradient(hsla(0, 0%, 100%, 0.08) 1px, transparent 0),
    linear-gradient(90deg, hsla(0, 0%, 100%, 0.08) 1px, var(--primary) 0);
  background-size: 56px 56px;
  padding: 20px;
  @media screen and (max-width: 767px) {
    padding: 16px 12px;
  }
}
