.assessmentModal {
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: 100%;

  .ant-modal-content {
    height: calc(var(--app-height));
    display: flex;
    flex-direction: column;
  }

  .ant-modal-header {
    padding: 0px;
    border: none;
  }

  .ant-modal-body {
    padding: 0px;
    border-radius: 0px;
    overflow: auto;
    height: 100%;
    max-height: calc(var(--app-height) - 60px);
    transition: all 0.3s ease-in;
    overflow-y: auto;
    position: relative;
    background-color: var(--background);

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &:hover {
      &::-webkit-scrollbar-thumb:horizontal,
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        transition: all 0.3s ease-in;
      }
    }
  }

  .assessmentFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .siteBtn {
      width: 100%;
    }
  }

  .assessmentModalContent {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    .assessmentModalContent__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
    }

    .assessmentModalContent__title {
      font-size: 20px;
      font-weight: 600;
      color: var(--text2);
      margin: 0;
      .assessmentModalContent__title__text {
        font-size: 18px;
      }
      .assessmentModalContent__title__subtext {
        font-size: 13px;
        font-weight: 400;
        color: var(--text4);
        .assessmentModalContent__title__subtext__inner {
          font-size: 15px;
        }
      }
    }

    // .assessmentModalContent__marks {
    //   .assessmentModalContent__marks__value {
    //     font-size: 16px;
    //   }
    // }

    .progressLinesContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 12px;

      .progressLines {
        width: 100%;
        height: 2px;
        border-radius: 4px;
        background-color: var(--tertiary);

        &.answered {
          background-color: var(--green);
        }
      }
    }

    .assessmentModalContent__question-header {
      display: flex;
      align-items: start;
      justify-content: space-between;
      gap: 12px;
      margin-top: 36px;
    }

    .assessmentModalContent__question {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: var(--text1);
    }

    .assessmentModalContent__options {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .assessmentModalContent__options__option {
        padding: 12px 18px;
        border: none;
        border-radius: 24px;
        background-color: var(--tertiary);
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: max-content;

        &.selected {
          background-color: var(--primary_light);
        }

        .assessmentModalContent__options__option__text {
          margin: 0;
          display: block;
          width: 100%;
          font-size: 14px;
          font-weight: 500;
          color: var(--text2);
          text-align: left;

          * {
            margin: 0;
            padding: 0;
            word-break: break-word;
            white-space: pre-wrap;
          }
        }

        .assessmentModalContent__options__option__tick {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          border: 2px solid var(--secondary);
        }

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      .assessmentModalContent__options__text {
        padding: 12px 8px;
        border-radius: 6px;
        background-color: var(--gray_lighter);

        .assessmentModalContent__options__attached_filename {
          display: flex;
          align-items: center;
          gap: 8px;
          width: fit-content;
          padding: 4px 12px;
          border-radius: 6px;
          border: 1px solid var(--border_input);
          background-color: var(--background);

          .assessmentModalContent__options__attached_filename_text {
            margin: 0;
          }

          .assessmentModalContent__options__attached_close {
            color: var(--danger);
            font-size: 16px;
            overflow: visible;
            // margin-right: 8px;
          }
        }

        .assessmentModalContent__options__text__title {
          font-size: 12px;
          font-weight: 600;
          color: var(--text1);
          text-transform: uppercase;
          margin: 0;
        }

        .assessmentModalContent__options__text__input {
          padding-inline: 0;
          background-color: transparent;
          min-height: 100px;
          max-height: 300px;
          width: 100%;
          border: none;
          outline: none;
          font-size: 14px;
          font-weight: 400;
          color: var(--text2);
        }

        .assessmentModalContent__options__text__submit {
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}
