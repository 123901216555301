.postdetails__container {
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .scroll__container {
    height: 100%;
    overflow-y: scroll;

    .repost-card {
      margin: 12px 12px 4px;
      // padding: 12px;
      border: 1px solid var(--border);
      border-radius: 6px;
      overflow: hidden;
    }
  }
}
