.reportModal {
    padding-bottom: 0 !important;
    max-height: 100%;

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        border-radius: 12px !important;
        box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
            inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
    }

    .ant-modal-header {
        border-radius: 12px 12px 0 0;
        padding: 0px;
        border: none;
        .default-header {
            border-radius: 12px 12px 0 0;
        }
    }

    .ant-modal-body {
        padding: 12px;
        border-radius: 0px 0px 12px 12px;
        overflow: hidden;
        height: 100%;
        min-height: calc(var(--app-height) - 440px);
        max-height: calc(var(--app-height) - 240px);
        transition: all 0.3s ease-in;
        overflow-y: auto;
        position: relative;
        background-color: var(--background);

        &::-webkit-scrollbar-track {
            // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 0px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            transition: all 0.3s ease-in;
        }

        &::-webkit-scrollbar-thumb:horizontal {
            background-color: transparent;
            transition: all 0.3s ease-in;
        }

        &:hover {
            &::-webkit-scrollbar-thumb:horizontal,
            &::-webkit-scrollbar-thumb {
                background-color: transparent;
                transition: all 0.3s ease-in;
            }
        }
    }
}
