// .profile__container {
//   width: 100%;
//   // height: 100%;
//   min-height: calc(100vh - 60px);
//   overflow-y: auto;
//   display: flex;
//   flex-direction: column;
//   background-color: var(--background);
// }

.profile__container {
  height: var(--app-height);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .profile__content {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.profileHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  margin-top: 12px;
  .profileName {
    width: calc(100% - 88px);
    margin-right: 12px;
    .profileName__name {
      font-size: 36px;
      font-weight: 600;
      color: var(--text1);
      margin: 0;
    }
  }
  .avatarHolder {
    border: 2px solid var(--primary);
    display: block;
    border-radius: 100%;
    padding: 4px;
    height: 76px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .camera__container {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    position: absolute;
    left: -5%;
    bottom: 5%;
    background-color: var(--primary);
  }
  .avatar-uploader {
    display: block;
    width: max-content;
    height: 76px;
  }
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
    border-radius: 100%;
    border: 0;
    display: block;
    width: max-content;
    height: 76px;

    > .ant-upload {
      padding: 0px;
    }
  }
}

.profile__shortcuts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 12px 12px 0 12px;

  .profile__shortcut {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 8px;
    border-radius: 8px;
    border: 1px solid var(--border);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    svg {
      width: 32px;
      height: 32px;
      align-self: flex-end;
      position: relative;
      z-index: 1;
    }
    span {
      font-size: 14px;
      color: var(--text1);
      margin-top: 6px;
      font-weight: 500;
      position: relative;
      z-index: 2;
    }
    &::before {
      content: '';
      background: linear-gradient(33deg, var(--background) 21%, currentColor);
      position: absolute;
      top: -75px;
      right: -60px;
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }
  }
}

.profile__settings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__footer {
    flex-grow: 1;
    padding: 12px;
    display: flex;
    align-items: end;
  }
}
