.home__container {
    display: flex;
    flex-direction: column;
    height: var(--app-height);
    .home__content {
        display: flex;
        flex-direction: column;
        height: calc(100% - 65px);
        flex-shrink: 0;
        background-color: var(--page_bg);
    }
    .bottom--area,
    .top--area {
        background-color: var(--background);
    }
}
