.creatorMangoWrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: var(--primary_light);
  margin-top: 12px;
  border-radius: 12px;
  padding: 10px;

  .creatorMangoWrapperInner {
    position: relative;
    background: var(--background);
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    .creatorMangoMedia {
      padding: 0;

      .mangoMedia {
        border-radius: 6px 6px 0 0;
        overflow: hidden;
        // ++++++++++++++++++ New Player +++++++++++++++++++ //
        .player-wrapper-drm,
        .player-wrapper-drm-custom {
          padding-top: 0;
          height: 100%;
          z-index: 99;
        }
        .player-wrapper-drm-custom > div {
          padding-top: 0 !important;
          height: 100%;
        }
        .video-js.vjs-fluid {
          padding-top: 0;
          height: 100%;
        }
        > * {
          position: absolute !important;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          object-fit: contain;
          object-position: center;
          width: 100%;
          height: 100%;
          margin: 0 auto;
        }
      }
    }

    .creatormangoDetails {
      padding: 12px 12px 0px;

      .creatorMangoTitle {
        font-size: 18px;
        margin: 0px;
        margin-bottom: 2px;
        color: var(--text1);
      }

      .mangoStartDate {
        font-size: 12px;
        font-weight: 600;
        color: var(--blue);
        text-transform: uppercase;
      }
      .creatorMangoDesc {
        font-size: 14px;
        font-weight: 500;
        color: var(--text2);
        position: relative;
        margin: 0;
      }
      .creatorMangoDesc > p {
        font-size: 14px;
        font-weight: 500;
        color: var(--text2);
        position: relative;
        margin: 0;
        display: inline-block;
      }
      .creatorMangoDesc > h3 {
        display: inline-block;
      }
    }

    .creatorMangoPriceWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      padding: 0 12px;
      margin-top: 5px;
      .mangoPrice {
        span {
          font-family: Arial, Helvetica, sans-serif;
        }
        font-weight: 600;
        font-size: 24px;
        color: var(--text1);
        margin: 0;
        .mangoPriceGST {
          font-weight: 500;
          font-size: 12px;
          color: var(--text3);
          margin-left: 4px;
        }
      }
    }

    .mangoPaymentCycle {
      font-size: 13px;
      font-weight: 600;
      color: var(--text3);
    }

    .mangoDivider {
      width: 100%;
      display: block;
      margin: 12px auto;
      border-top: 1px dashed #000;
      position: relative;
      &:before,
      &:after {
        content: '';
        width: 12px;
        height: 25px;
        display: block;
        position: absolute;
        top: -12.5px;
      }
      &::before {
        background: url(https://tagmango.com/staticassets/circleleft.svg-1624527595911.svg);
        left: -1px;
      }
      &::after {
        background: url(https://tagmango.com/staticassets/circleright.svg-1624527813620.svg);
        right: -1px;
      }
    }

    .creatorMangoActionButtons {
      display: flex;
      align-items: center;
      padding: 0 12px 12px;
      > .siteBtn + .siteBtn {
        margin-left: 12px;
      }

      .siteBtn {
        span {
          font-weight: 600;
          letter-spacing: 0.025em;
        }
      }

      .siteBtnSecondary {
        height: 43px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &.shareMango {
          width: 45px;
          flex-shrink: 0;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .shareMangoBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-right: 12px;
        }
      }
    }
  }
}

.mangoMediaCarousel {
  .slick-dots {
    margin: 0;

    li button {
      &::before {
        content: none;
      }
    }
  }

  .slick-prev,
  .slick-next {
    z-index: 9;
    margin-top: 0;
    background: rgba(66, 66, 66, 0.625);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    width: 36px;
    height: 32px;
  }

  .slick-prev {
    left: 0;
    border-radius: 0 4px 4px 0;

    &:before {
      content: '';
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 16px;
      background-image: url(./arrow-left.svg);
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      top: 0;
    }
  }

  .slick-next {
    right: 0;
    border-radius: 4px 0 0 4px;

    &:before {
      content: '';
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 16px;
      background-image: url(./arrow-right.svg);
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      top: 0;
    }
  }
}
