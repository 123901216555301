.message-contents-reply {
  background-color: rgba(0, 0, 0, 0.08);
  min-width: 200px;
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
  border-left: 4px solid var(--primary);
  margin-block: 4px;

  .reply-contents {
    width: 100%;
  }
}

.input-contents-reply {
  background-color: var(--gray_light);
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-left: 4px solid var(--primary);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 12px;

  .close {
    background-color: var(--text3);
    flex-shrink: 0;
    margin-left: 12px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}
.reply-contents {
  width: calc(100vw - 90px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .reply-contents-header {
    width: 100%;

    .reply-contents-header-name {
      font-size: 13px;
      font-weight: 600;
      color: var(--text1);
      margin: 0;
    }

    .reply-contents-header-message {
      font-size: 13px;
      font-weight: 400;
      color: var(--text2);
      margin: 2px 0 0 0;
      align-items: center;
      display: flex;

      > * {
        margin: 0;
      }
    }
  }
  .reply-contents-attachment {
    height: 40px;
    width: 40px;
    flex-shrink: 0;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
