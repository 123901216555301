.ant-tabs-nav-list {
  width: 100%;
  .ant-tabs-tab {
    flex: 1;
    text-align: center;
    height: 55px;
    .ant-tabs-tab-btn {
      color: var(--text2);
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      text-align: center;
    }
  }
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-nav-operations {
  display: none !important;
}

.workshops__container {
  height: var(--app-height);
  overflow: hidden;
  &,
  .ant-tabs {
    display: flex;
    flex-direction: column;
  }
  .ant-tabs-nav {
    margin: 0 !important;
    border-top: 1px solid var(--border);
  }
  .ant-tabs {
    height: calc(var(--app-height) - 55px);
  }
  .ant-tabs-content {
    height: 100%;
    .ant-tabs-tabpane {
      height: 100%;
    }
  }
  .workshops__content {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    padding: 12px;
    background-color: var(--background);
  }
}

.workshop__download__item {
  background-color: var(--background);
  padding: 8px 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border);
  cursor: pointer;
  .workshop__download__item--pressable {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: var(--gray_light);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 16px;
      width: 16px;
    }
  }
}
