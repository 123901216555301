.subscriptionList {
  background-color: var(--page_bg);
  padding-top: 12px;

  .subscription {
    background-color: var(--background);
    padding: 0.1px 12px;
    margin-bottom: 12px;

    .cancelSubscription {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 6px 12px;
      gap: 0 8px;
      //   width: 100%;
      margin-left: auto;
      margin-bottom: 12px;
      background-color: rgba($color: #ea2440, $alpha: 0.2);
      color: #ea2440;

      span {
        font-size: 14px;
      }
    }

    .creatorDetails {
      display: flex;
      align-items: center;
      padding: 12px 0;
      // border-bottom: 1px solid var(--border);

      .creatorName {
        margin: 0;
        margin-left: 12px;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .tier {
      display: flex;
      align-items: center;
      padding: 4px 0;

      .tierOverview {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: calc(100% - 90px);

        .tierName {
          font-size: 14px;
          font-weight: 500;
          color: var(--text1);
          margin-bottom: 4px;
        }

        .tierDetails {
          font-size: 12px;
          color: var(--text3);
          margin: 0;
        }
      }

      .tierPricing {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: 16px;
        padding-left: 16px;
        position: relative;
        flex: 0 0 70px;

        // &:before {
        //   content: '';
        //   width: 1px;
        //   height: 50px;
        //   display: block;
        //   position: absolute;
        //   left: 0;
        //   top: auto;
        //   background-color: var(--border);
        // }

        .amount {
          font-size: 16px;
          font-weight: 600;
          color: var(--text1);
          white-space: nowrap;

          .inputRupee {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
          }
        }

        .paymentCycle {
          font-size: 11px;
          color: var(--text3);
        }
        .autopay {
          display: flex;
          background-color: var(--secondary);
          color: var(--background);
          padding: 2px;
          padding-right: 6px;
          gap: 2px;
          border-radius: 20px;
          font-size: 9px;
          font-weight: 400;
          align-items: center;
          margin-top: 6px;
          opacity: 0.5;

          &.autopayEnabled {
            background-color: var(--green);
            opacity: 1;
          }
        }
      }
    }

    .subscriptionFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 8px 0;
      padding: 12px 0;
      // border-top: 1px solid var(--border);

      .expiryDetails {
        display: flex;
        align-items: center;

        > img {
          margin-right: 5px;
        }

        .expiryDate {
          font-size: 12px;
          font-weight: 500;
          color: var(--danger);
        }
      }

      .siteBtn {
        > span {
          font-size: 14px;
        }
      }
    }
  }
}
