.messageInfoModal {
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
    max-width: 100%;

    .ant-modal-content {
        height: calc(var(--app-height));
        display: flex;
        flex-direction: column;
    }

    .ant-modal-header {
        padding: 0px;
        border: none;
    }

    .ant-modal-body {
        padding: 0px;
        border-radius: 0px;
        overflow: hidden;
        height: 100%;
        max-height: calc(var(--app-height) - 60px);
        transition: all 0.3s ease-in;
        overflow-y: auto;
        position: relative;
        background-color: var(--page_bg);

        &::-webkit-scrollbar-track {
            // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 0px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            transition: all 0.3s ease-in;
        }

        &::-webkit-scrollbar-thumb:horizontal {
            background-color: transparent;
            transition: all 0.3s ease-in;
        }

        &:hover {
            &::-webkit-scrollbar-thumb:horizontal,
            &::-webkit-scrollbar-thumb {
                background-color: transparent;
                transition: all 0.3s ease-in;
            }
        }
    }

    .messageInfoModal__card {
        padding: 0 12px;
        background-color: var(--background);
        &:not(:last-child) {
            margin-bottom: 12px;
        }
        .messageInfoModal__card__title {
            font-size: 13px;
            font-weight: 500;
            color: var(--text2);
            padding: 12px 0px;
            margin: 0;
        }
    }

    .chatRoomInfoModal__participant {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        .chatRoomInfoModal__participant__inner {
            margin-left: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 40px);
            padding: 12px 0px;
            .chatRoomInfoModal__participant__inner__text {
                font-size: 14px;
                font-weight: 500;
                color: var(--text1);
                margin: 0;
                margin-right: 8px;
            }
        }
        &:not(:last-child) {
            .chatRoomInfoModal__participant__inner {
                border-bottom: 1px solid var(--border);
            }
        }
    }
    .messageList__dateContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .messageList__date {
            text-align: center;
            text-transform: uppercase;
            color: var(--text3);
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.025em;
            margin: 0;
        }
    }
}
