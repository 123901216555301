.mango-room-card {
  background-color: var(--background);
  margin-block: 0;
  padding: 12px;
  // padding-block: 16px;

  .mango-room-card__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding-bottom: 12px;
    // border-bottom: 1px solid var(--border);

    .mango-room-card__header__content {
      margin: 0 8px;
      width: calc(100vw - 134px);
      flex-grow: 1;

      h5 {
        font-size: 12px;
        font-weight: 500;
        color: var(--text3);
        margin: 0;
        margin-bottom: 4px;
      }

      h3 {
        font-size: 16px;
        font-weight: 600;
        color: var(--text1);
        margin: 0;
        width: calc(100vw - 108px);
      }
    }

    .mango-room-card__header__unread-count {
      min-width: 20px;
      height: 20px;
      flex-shrink: 0;
      border-radius: 20px;
      padding: 2px 6px;
      background-color: var(--link);
      display: flex;
      justify-content: center;
      align-items: center;

      .mango-room-card__header__unread-count__text {
        font-size: 12px;
        font-weight: 500;
        color: var(--background);
        margin: 0;
      }
    }
  }
  .mango-room-card__recent-messages {
    padding: 12px 0 4px;
    .mango-room__recent-message-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
      .mango-room__recent-message-item__text {
        font-weight: 400;
        font-size: 12px;
        margin: 0;
        width: 100%;
        margin: 0 8px;
      }
      .mango-room__recent-message-item__time {
        font-weight: 400;
        font-size: 12px;
        margin: 0;
        width: max-content;
        flex-shrink: 0;
      }
    }
  }
  .mango-room-card__participants {
    display: flex;
    flex-direction: row;
    align-items: center;
    .participant-extra-text__wrapper {
      height: 22px;
      min-width: 22px;
      border-radius: 22px;
      border: 1px solid var(--border);
      background-color: var(--background);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -8px;
      padding: 2px 6px;
      z-index: 1;
      position: relative;
      .participant-extra-text {
        font-size: 9px;
        font-weight: 500;
        color: var(--text1);
        margin: 0;
      }
    }
    .mango-room-card__participants__extra--text {
      font-size: 11px;
      font-weight: 500;
      color: var(--text1);
      margin: 0;
      margin-left: 6px;
    }
  }
}
