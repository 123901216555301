.new-message {
    .new-message__header {
        padding: 12px;
        .new-message__header-text {
            font-size: 14px;
            font-weight: 400;
            color: var(--text3);
            margin: 0;
        }
    }
    .new-message__item {
        padding: 0 12px;
        background-color: var(--background);
        display: flex;
        flex-direction: row;
        align-items: center;
        .new-message__item-text {
            margin-left: 8px;
            padding: 12px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: calc(100vw - 57px);
            .new-message__item-text-name {
                font-size: 14px;
                font-weight: 500;
                color: var(--text1);
                margin: 0;
            }
        }
    }
}
