.customInput {
    &.clickable {
        cursor: pointer;
    }
    .input__container {
        padding: 32px 12px 8px;
        border: 1px solid var(--text4);
        border-radius: 8px;
        position: relative;
        transition: all 0.2s ease-in-out;
        display: flex;
        .input__label {
            position: absolute;
            font-weight: 500;
            top: 25.5px;
            left: 12px;
            font-size: 16px;
            line-height: 16px;
            color: var(--text4);
            transition: all 0.2s ease-in-out;
            pointer-events: none;
        }
        .input__field,
        .input__content > *,
        .input__addonBefore > *,
        > * {
            border: none;
            outline: 0;
            font-size: 16px;
            font-weight: 400;
            padding: 0;
            margin: 0;
            width: 100%;
            color: var(--text1);
            font-family: "Poppins", sans-serif;
        }
        .input__field {
            height: 25px;
            background: none;
        }
        .input__field--textarea {
            min-height: 25px;
            max-height: 150px;
            resize: none;
        }
        .input__addonBefore {
            width: max-content;
            flex-shrink: 0;
            margin-right: 6px;
            > * {
                color: var(--text1);
            }
        }
        &.input__container--focused,
        &.input__container--filled {
            .input__label {
                top: 10px;
                font-size: 13px;
                line-height: 13px;
            }
        }
        &.input__container--focused {
            border: 1px solid var(--primary);
            .input__label {
                color: var(--primary);
            }
        }
        &.input__container--disabled {
            opacity: 0.8;
        }

        .input__content {
            min-height: 25px;
        }
    }
    .input__counter {
        position: absolute;
        top: 6px;
        right: 12px;
        text-align: end;
        .input__counter__text {
            font-size: 13px;
            line-height: 13px;
            font-weight: 500;
            color: var(--text2);
            margin: 0;
        }
    }
    .input__message {
        margin: 0;
        margin-top: 4px;
        font-size: 12px;
        font-weight: 500;
        color: var(--primary);
        &.input__message--error {
            color: var(--danger);
        }
        &.input__message--success {
            color: var(--green);
        }
        &.input__message--info {
            color: var(--blue);
        }
    }
    &:not(:first-child) {
        margin-top: 16px;
    }
}

.select__bottomSheet {
    [data-rsbs-header] {
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .select__options {
        list-style: none;
        padding: 12px;
        margin: 0;
        .select__option {
            padding: 8px 12px;
            border: 1px solid var(--gray_light);
            display: flex;
            align-items: center;
            border-radius: 8px;
            cursor: pointer;
            .select__option__text {
                font-size: 14px;
                font-weight: 500;
                color: var(--text3);
                width: 100%;
                margin-right: 12px;
                display: block;
            }
            svg {
                width: 24px;
                height: 24px;
            }
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
}
