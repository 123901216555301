.feed-banners-slider {
  .slick-slider {
    .slick-track {
      margin-bottom: -6px;
    }
  }

  .slick-dots {
    bottom: -8px;
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      flex: 0 1 auto;
      box-sizing: content-box;
      width: 16px;
      height: 3px;
      margin-inline: 4px;
      padding: 0;
      text-align: center;
      text-indent: -999px;
      vertical-align: top;
      transition: all 0.3s;

      button {
        position: relative;
        display: block;
        width: 100%;
        height: 3px;
        padding: 0;
        color: transparent;
        font-size: 0;
        background: rgba(0, 0, 0, 0.3);
        border: 0;
        border-radius: 1px;
        outline: none;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s;

        &::before {
          display: none;
        }
      }

      &.slick-active {
        width: 24px;

        button {
          background: #ffffff;
          opacity: 1;
        }
      }
    }
  }
}
