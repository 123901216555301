.imageViewerModal {
    padding-bottom: 0 !important;
    max-height: 100%;

    .ant-modal-content {
        border-radius: 12px !important;
        box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
            inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
    }

    .ant-modal-header {
        border-radius: 12px 12px 0 0;
        padding: 8px 12px;
    }

    .ant-modal-body {
        padding: 0px;
        border-radius: 0px;
        overflow: hidden;
        height: calc(var(--app-height) - 240px);
        transition: all 0.3s ease-in;
        overflow: auto;
        position: relative;

        &::-webkit-scrollbar-track {
            // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            background-color: #fff;
        }

        &::-webkit-scrollbar {
            width: 5px;
            background-color: #fff;
        }

        &::-webkit-scrollbar:horizontal {
            height: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            transition: all 0.3s ease-in;
        }

        &::-webkit-scrollbar-thumb:horizontal {
            background-color: transparent;
            transition: all 0.3s ease-in;
        }

        &:hover {
            &::-webkit-scrollbar-thumb:horizontal,
            &::-webkit-scrollbar-thumb {
                background-color: #bdbdbd;
                transition: all 0.3s ease-in;
            }
        }
        &.transparent {
            &::-webkit-scrollbar-track {
                // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
                background-color: rgba(255, 255, 255, 0);
            }

            &::-webkit-scrollbar {
                width: 5px;
                background-color: rgba(255, 255, 255, 0);
            }

            &::-webkit-scrollbar:horizontal {
                height: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: transparent;
                transition: all 0.3s ease-in;
            }

            &::-webkit-scrollbar-thumb:horizontal {
                background-color: transparent;
                transition: all 0.3s ease-in;
            }

            &:hover {
                &::-webkit-scrollbar-thumb:horizontal,
                &::-webkit-scrollbar-thumb {
                    background-color: #bdbdbd00;
                    transition: all 0.3s ease-in;
                }
            }
        }
    }
    .title {
        font-weight: 600;
        color: var(--text1) !important;
        margin: 0 30px 0 0 !important;
        font-size: 17px;
    }
    .modalHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px 12px;
        .pageNumber {
            font-size: 14px;
            font-weight: 600;
            color: var(--text1);
            margin: 0 12px 0 8px !important;
            white-space: nowrap;
        }
        .buttonWrapper {
            display: flex;
            align-items: center;
            .ant-btn {
                border-radius: 4px;
                border: none;
                padding: 4px;
                height: 40px;
                width: 40px;
                font-size: 14px;
                font-weight: 600;
                background-color: var(--tertiary);
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    color: var(--icon);
                    height: 25px;
                    width: 25px;
                }
                &:not(:last-child) {
                    margin-right: 8px;
                }
                &:last-child {
                    margin-left: 8px;
                }

                &[disabled] {
                    svg {
                        color: var(--secondary);
                    }
                }
                &.closeModal {
                    background-color: transparent;
                    padding: 0;
                    border-radius: 50%;
                    svg {
                        height: 40px;
                        width: 40px;
                    }
                }
            }
        }
    }
    .footerWrapper {
        display: flex;
        align-items: center;
        gap: 8px 12px;
        overflow-x: auto;
        .imageWrapper {
            flex-shrink: 0;
            width: 180px;
            height: 120px;
            object-fit: cover;
            background-color: var(--tertiary);
            border: none;
            border-radius: 6px;
            border: 2px solid transparent;
            transition: all 0.3s ease-in;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 6px;
            }
            &.active {
                border: 2px solid var(--primary);
            }
        }
    }
    .mainImageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        transform-origin: top left;
        img {
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
        }
    }
}
