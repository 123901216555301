.reviewAnswersheetModal {
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: 100%;

  .ant-modal-content {
    height: calc(var(--app-height));
    display: flex;
    flex-direction: column;
  }

  .ant-modal-header {
    padding: 0px;
    border: none;
  }

  .ant-modal-body {
    padding: 0px;
    border-radius: 0px;
    overflow: auto;
    height: 100%;
    max-height: calc(var(--app-height) - 60px);
    transition: all 0.3s ease-in;
    overflow-y: auto;
    position: relative;
    background-color: var(--background);

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &:hover {
      &::-webkit-scrollbar-thumb:horizontal,
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        transition: all 0.3s ease-in;
      }
    }
  }

  .reviewAnswersheetModal__header {
    background-color: var(--background);
    padding: 12px;
    width: 100%;
    .reviewAnswersheetModal__header__title {
      color: var(--text2);
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }
    .reviewAnswersheetModal__header__question-pill {
      padding: 4px 8px;
      background-color: var(--primary_light);
      border-radius: 6px;
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: max-content;
      .reviewAnswersheetModal__header__question-pill__text {
        color: var(--text2);
        font-size: 10px;
        font-weight: 500;
        margin-left: 8px;
      }
    }
    .reviewAnswersheetModal__header__list {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      .reviewAnswersheetModal__header__list__item {
        display: flex;
        .reviewAnswersheetModal__header__list__item__text {
          color: var(--text3);
          font-size: 12px;
          font-weight: 400;
          span {
            color: var(--text2);
            font-weight: 500;
          }
        }
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
    .reviewAnswersheetModal__header__status {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 8px;
      border-radius: 6px;
      margin-top: 8px;
      .reviewAnswersheetModal__header__status__icon {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        svg {
          width: 12px;
          height: 12px;
        }
      }
      .reviewAnswersheetModal__header__status__text {
        color: var(--text2);
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
}

.review-answersheet-item {
  background-color: var(--background);
  padding: 12px;
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .review-answersheet-item__number {
    height: 25px;
    min-width: 25px;
    padding: 4px;
    border-radius: 20px;
    background-color: var(--tertiary);
    display: flex;
    justify-content: center;
    align-items: center;
    .review-answersheet-item__number__text {
      color: var(--text1);
      font-size: 12px;
      font-weight: 400;
    }
  }
  .review-answersheet-item__question__header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 12px;
  }
  .review-answersheet-item__question-title {
    color: var(--text1);
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  .review-answersheet-item__question-total-marks {
    color: var(--text2);
    font-size: 12px;
    font-weight: 500;
  }
  .review-answersheet-item__answer-wrapper {
    background-color: var(--gray_lighter);
    border-radius: 6px;
    margin-top: 10px;

    .status__text {
      color: var(--text1);
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
    }

    .review-answersheet-item__answer-wrapper__inner {
      .review-answersheet-item__answer {
        padding: 8px 12px;
        border-bottom: 1px solid var(--secondary);
        .review-answersheet-item__answer__title {
          color: var(--text1);
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          margin: 0;
        }
        .review-answersheet-item__answer__text {
          color: var(--text1);
          font-size: 11px;
          font-weight: 400;
          margin: 0;
        }
        .review-answersheet-item__answer__option {
          padding: 4px 12px;
          border: none;
          border-radius: 12px;
          background-color: var(--tertiary);
          border: 1px solid var(--secondary);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: max-content;
          font-size: 10px;
          font-weight: 500;
          color: var(--text1);
          text-align: left;
          * {
            margin: 0;
            padding: 0;
          }
          &:not(:last-child) {
            margin-bottom: 6px;
          }
        }
      }
    }
    .review-answersheet-item__marks__question-marks {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;
      .review-answersheet-item__marks__question-marks__marks {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        .review-answersheet-item__marks__question-marks__marks__mark {
          min-width: 25px;
          height: 25px;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--secondary);
        }
      }
    }
    .review-answersheet-item__marks__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      padding: 8px 12px;
      .review-answersheet-item__marks__question-status {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .review-answersheet-item__marks__question-status__icon {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          svg {
            width: 14px;
            height: 14px;
          }
        }
      }
      .review-answersheet-item__marks__obtained {
        color: var(--text3);
        font-size: 12px;
      }
    }
    .review-answersheet-item__not-answered {
      padding: 8px 12px;
    }
  }
}
