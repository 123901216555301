.chatInputDisableWrapper {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  background-color: var(--background);
  align-items: center;
  color: var(--text1);
  font-size: 14px;

  .anticon,
  span {
    color: #ffc02e;
    font-size: 16px;
    margin-right: 6px;
  }
}
