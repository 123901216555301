.creator-tag {
  background-color: var(--text2);
  padding: 2px 6px;
  border-radius: 6px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 6px;
  color: var(--creator_label);
  font-size: 9px;
  font-weight: 500;
  width: max-content;
  height: max-content;
  align-self: center;
}
