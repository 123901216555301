// Points List style
.points__container {
  .my-points-card {
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background-color: var(--primary);
    color: var(--background);
    padding: 12px;
    margin: 12px;

    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }

    &__details {
      &__title {
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      &__points {
        font-size: 32px;
        font-weight: 600;
        line-height: 1;

        span {
          font-size: 20px;
        }
      }
    }
  }

  .points-details__item {
    margin-bottom: 12px;

    &__title {
      margin-bottom: 16px;
      position: relative;
      z-index: 0;

      & > span:not(.creator-tag) {
        display: block;
        color: var(--text3);
        font-size: 12px;
        width: max-content;
        text-align: center;
        padding-inline: 4px;
        margin-inline: auto;
        background-color: white;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 1px;
        background-color: var(--border);
        z-index: -1;
      }
    }

    // &__list {
    //   display: flex;
    //   flex-direction: column;
    //   gap: 24px;

    .list-item {
      margin-bottom: 12px;

      &__details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding-inline: 12px;

        &__points {
          font-weight: 500;
          color: var(--green);
          white-space: nowrap;
        }

        &__remark-btn {
          margin-inline: 12px;
          text-decoration: underline;
          color: var(--text3);
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
    // }
  }
}

// Points remark
.points__remark {
  margin: 12px;
  margin-bottom: 48px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: var(--border);
}
