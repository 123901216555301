.manageCreatorsModal {
    padding-bottom: 0 !important;
    max-height: 100%;

    .ant-modal-content {
        border-radius: 12px !important;
        box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
            inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
    }

    .ant-modal-header {
        border-radius: 12px 12px 0 0;
        padding: 4px 0px;
        margin: 0 15px;
    }

    .ant-modal-body {
        padding: 8px 0px;
        border-radius: 12px 12px 0 0;
        overflow: hidden;
        height: 100%;
        max-height: calc(var(--app-height) - 240px);
        transition: all 0.3s ease-in;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar-track {
            // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 0px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            transition: all 0.3s ease-in;
        }

        &::-webkit-scrollbar-thumb:horizontal {
            background-color: transparent;
            transition: all 0.3s ease-in;
        }

        &:hover {
            &::-webkit-scrollbar-thumb:horizontal,
            &::-webkit-scrollbar-thumb {
                background-color: transparent;
                transition: all 0.3s ease-in;
            }
        }
    }

    .titleWrapper {
        position: relative;
        display: block;
        padding: 12px 0;
        .title {
            text-align: center;
            font-weight: 600;
            color: var(--text1) !important;
            margin: 0 !important;
            font-size: 17px;
        }
    }

    .manageCreatorsModal__item {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 6px 12px;
        cursor: pointer;
        .manageCreatorsModal__item__name {
            font-weight: 500;
            color: var(--text2);
            font-size: 14px;
            margin: 0;
            width: 100%;
        }
        &.header {
            padding: 0;
            padding-bottom: 8px;
            .compressedIconWrapper {
                span {
                    border: 1px solid var(--secondary);
                }
                span:first-child {
                    margin-top: 14px;
                }
                span:last-child {
                    margin-left: -12px;
                }
            }
        }
    }
}
