.chat-avatar__container {
    height: 49px;
    width: 49px;
    border-radius: 50%;
    padding: 1.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border);
    .chat-avatar__initials {
        display: flex;
        justify-content: center;
        align-items: center;
        .chat-avatar__initials-text {
            font-size: 16px;
            font-weight: 600;
            color: var(--primary);
        }
    }
}
