.dmChatScreenHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 35px 15px 45px;
  .dmChatScreenHeader__title {
    font-size: 17px;
    font-weight: 600;
    color: var(--text1);
    margin: 12px 0;
  }
  .dmChatScreenHeader__mangoesInCommon {
    display: flex;
    flex-direction: column;
    gap: 6px;
    .dmChatScreenHeader__mangoesInCommon__inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px;
      span {
        font-size: 12px;
        font-weight: 400;
        color: var(--text3);
        text-align: center;
      }
      .dmChatScreenHeader__mangoesInCommon__inner__more {
        background: var(--page_bg);
        padding: 0px 4px;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
        span {
          font-size: 12px;
          font-weight: 500;
          color: var(--text2);
          text-align: center;
        }
      }
    }
  }
}
