.course__list__card {
  background-color: var(--background);
  // margin-top: 6px;
  padding: 6px;
  border-radius: 12px;
  margin: 12px;
  margin-bottom: 0px;
  border: 1px solid var(--border);

  .course__list__card__image__wrapper {
    // margin-bottom: 6px;
    aspect-ratio: 16/9;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    .course__list__card__image {
      // width: 80px;
      // height: 60px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: var(--tertiary);
    }

    .course__list__card__image__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;

      .course__list__card__image__overlay__icon {
        background-color: var(--primary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;

        svg {
          height: 32px;
          width: 32px;
          fill: var(--background);
        }
      }
    }
  }
  .course__list__card__content {
    // display: flex;
    // flex-direction: row;
    // align-items: flex-start;
    padding: 6px;
    position: relative;
    .course__list__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      .course__list__card__tag__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2px;
        .course__list__card__tag__wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        button {
          margin-top: -15px;
          background-color: transparent !important;
          &[ant-click-animating-without-extra-node='true']::after {
            display: none;
          }
        }
      }
      .course__list__card__title__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .postMangoesWrapper {
          max-width: 75%;
        }
      }
      .course__list__card__title {
        font-size: 16px;
        font-weight: 600;
        color: var(--text1);
        margin: 0;
      }
      .course__list__card__subtitle {
        font-size: 12px;
        font-weight: 500;
        color: var(--text3);
        margin: 0;
      }
    }
  }

  .course__list__card__button {
    margin-top: 6px;
    width: 100%;
  }

  .progress__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span:first-child {
      font-size: 12px;
      font-weight: 500;
      color: var(--text2);
    }
    span:last-child {
      font-size: 12px;
      font-weight: 400;
      color: var(--text3);
    }
  }
  .course__list__card__button {
    width: 100%;
    span {
      font-weight: 500;
      font-size: 14px;
    }
    .course__list__card__button__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: var(--background);
      }
      div {
        width: 20px;
      }
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  // .progressbar__inner {
  //   background-color: var(--primary);
  // }
}
