.chatMediaWrapper {
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  $media-width: calc((100vw - 92px) * 0.8);

  .chatImageWrapper {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .chatVideoWrapper {
    min-width: $media-width;
    width: 100%;

    .player-wrapper {
      min-width: $media-width;
      max-width: 400px;
      padding-top: 75%;
      height: 100%;
      position: relative;
      .post-player {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .chatAudioWrapper {
    position: relative;
    padding: 8px;
    background-color: #4a4a59;
    border-radius: 12px;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: $media-width;
    max-width: 400px;
    .audioComponent.audio {
      background-color: transparent;
    }
    .pausePlay {
      flex-shrink: 0;
      svg {
        width: 32px;
        height: 32px;
      }
    }
    .bar {
      background-color: transparent;
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
      width: 100%;
      padding: 4px 2px 4px 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .bar__time {
        font-size: 11px;
        min-width: 55px;
        text-align: center;
        align-items: center;
        flex-shrink: 0;
        opacity: 0.75;
      }
    }
    .pausePlay {
      margin: 0;
      > span {
        font-size: 32px;
        line-height: 0;
      }
    }
    .ant-slider-rail {
      background-color: rgba(255, 255, 255, 0.225);
    }
    .ant-slider .ant-slider-track {
      background-color: rgba(255, 255, 255, 1) !important;
    }
    .ant-slider .ant-slider-handle {
      border: 0px !important;
      width: 12px;
      height: 12px;
      margin-top: -4px;
    }
    .ant-slider {
      margin: 0 12px;
      &:hover,
      &:focus {
        .ant-slider-track {
          background-color: rgba(255, 255, 255, 0.95) !important;
        }
        .ant-slider-handle {
          border: 0px !important;
          box-shadow: 0 0 0 5px rgba(255, 243, 230, 0.12) !important;
        }
      }
    }
  }
  .docWrapper {
    position: relative;
    padding: 8px;
    background-color: var(--primary);
    border-radius: 12px;
    display: flex;
    align-items: center;
    width: $media-width;
    max-width: 400px;
    .docIcon {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
    }
    .docDetails {
      padding-left: 8px;
      padding-right: 24px;
      width: calc(100% - 68px);
    }
    .download {
      flex-shrink: 0;
    }
    .docTitle {
      color: white;
      font-weight: 600;
      font-size: 14px;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 2px;
      width: 100%;
    }
    .docInfo {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.95);
      .docType {
        text-transform: uppercase;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        letter-spacing: 0.0625em;
      }
      .bulletSeparator {
        padding: 0 4px;
        &:before {
          content: '•';
          font-size: 12px;
          color: rgba(255, 255, 255, 0.95);
        }
      }
      .docSize {
        text-transform: uppercase;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
      }
    }
  }
}
