.qna__reply__item {
    padding: 12px;
    display: flex;
    flex-direction: row;
    .qna__reply__item__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .qna__reply__item__header__title {
            font-size: 12px;
            font-weight: 400;
            color: var(--text4);
            margin: 0;
            .qna__reply__item__header__title__creator {
                padding: 2px 6px;
                background-color: var(--text2);
                color: var(--creator_label);
                border-radius: 4px;
                margin: 0 4px;
                font-size: 9px;
                font-weight: 500;
            }
            .qna__reply__item__header__title__time {
                font-size: 12px;
                font-weight: 400;
                color: var(--text4);
                margin: 0;
            }
        }
        .qna__reply__item__header__menu__wrapper {
            height: 14px;
            transform: translate(-4px, -6px);
        }
    }
    .qna__reply__item__question {
        font-size: 14px;
        font-weight: 500;
        color: var(--text1);
        margin: 0;
        margin-top: 6px;
    }
    .qna__reply__item__description {
        font-size: 13px;
        font-weight: 400;
        color: var(--text1);
        margin: 0;
        .ant-typography-expand {
            font-size: 13px;
            font-weight: 600;
            color: var(--text1);
        }
    }
    .qna__reply__item__reply {
        font-size: 14px;
        font-weight: 500;
        color: var(--blue);
        margin: 0;
        margin-top: 12px;
        display: flex;
        align-items: center;
        .qna__reply__item__reply__separator {
            font-size: 10px;
            font-weight: 400;
            color: var(--text4);
            margin: 0 8px;
        }
        .qna__reply__item__reply__answered {
            font-size: 11px;
            font-weight: 500;
            color: var(--green);
            margin: 0;
            display: flex;
            align-items: center;
            gap: 6px;
        }
    }
}
