.custom-image-preview {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  cursor: zoom-in;
  background-color: rgba(0, 0, 0, 0.75);

  & > img {
    width: 100%;
    // height: 100%;
    height: 60px;
    object-fit: contain;
    display: block;
  }

  & > .ant-image {
    .ant-image-img {
      width: 100%;
      // height: 100%;
      height: 60px;
      object-fit: contain;
      display: block;
    }
  }

  &__blur-bg {
    transform: translate3d(0, 0, 0) translateZ(0);
    perspective: 1000px;
    backface-visibility: hidden;
    -moz-transform: translate3d(0, 0, 0) translateZ(0);
    -moz-perspective: 1000px;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0) translateZ(0);
    -webkit-perspective: 1000px;
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.4;
    filter: blur(5px);
    -webkit-filter: blur(5px);

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      background: rgba(0, 0, 0, 0.5);
    }

    &__image {
      width: 100%;
      display: block;
      height: 100%;
    }
  }
}
