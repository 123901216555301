.points-pill {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--primary_light);
  border-radius: 50px;
  width: max-content;

  &.sm {
    gap: 4px;
    padding: 4px;
    font-size: 10px;
  }

  .info-icon {
    color: grey;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: white;
      background-color: var(--primary);
    }
  }

  &.info-addon {
    // padding-block: 0;
    // padding-right: 0;

    .info-icon {
      width: 32px;
      height: 32px;
    }

    &.sm {
      .info-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
