.course-navigator__header {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 60px;
  border-top: 1px solid var(--border);
  .course-navigator__header__item {
    display: flex;
    align-items: center;
    justify-content: center;
    // justify-content: space-between;
    height: 100%;
    width: 100%;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background-color: var(--gray_lighter);
    }
    .course-navigator__icon {
      flex-shrink: 0;
      //   margin-right: -15px;
      //   &.previous {
      //     margin-right: 0;
      //     margin-left: -15px;
      //   }
    }
    .course-navigator__header__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      * {
        text-align: left;
      }
      .course-navigator__header__content__title {
        font-size: 14px;
        font-weight: 500;
        color: var(--text2);
        margin: 0;
      }
      &.previous {
        align-items: flex-end;
        * {
          text-align: right;
        }
      }
    }
  }
  .separator {
    width: 2px;
    height: 100%;
    background-color: var(--border);
  }
}

.courseMediaWrapper {
  background-color: rgba(0, 0, 0, 0.78);
  .courseImageWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    z-index: 1;
    cursor: zoom-in;
    background-color: rgba(0, 0, 0, 0.78);
    display: grid;
    aspect-ratio: 1200 / 628;
    .mainImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      aspect-ratio: 1200 / 628;
    }
    .blurImg {
      transform: translate3d(0, 0, 0) translateZ(0);
      perspective: 1000px;
      backface-visibility: hidden;
      -moz-transform: translate3d(0, 0, 0) translateZ(0);
      -moz-perspective: 1000px;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0) translateZ(0);
      -webkit-perspective: 1000px;
      -webkit-backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.4;
      filter: blur(5px);
      -webkit-filter: blur(5px);
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: block;
        background: rgba(0, 0, 0, 0.5);
      }
      > img {
        width: 100%;
        display: block;
        height: 100%;
      }
    }
  }
  .courseVideoWrapper {
    max-width: 400px;
    margin: 0 auto;
  }
}

.courseDetails__wrapper {
  .article-content {
    position: relative;
    .artical-fullscreen-button {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
      right: 0;
      border-radius: 15px;
      background-color: var(--gray_light);
      margin: 10px;
    }
    .slimScroll {
      padding: 12px;
      padding-bottom: 50px;
      padding-top: 30px;
      overflow-y: auto;
      max-height: 40vh;
      * {
        word-break: break-word;
        white-space: pre-wrap;
      }
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      --gradient-color-top: color-mix(in srgb, var(--background), #0000 50%);
      background-image: linear-gradient(
        to bottom,
        var(--gradient-color-top),
        var(--background)
      );
      content: '';
    }
  }
}

.course-navigator {
  [data-rsbs-backdrop] {
    z-index: 10;
  }
  [data-rsbs-overlay] {
    z-index: 10;
  }
}
