.pdfViewerModal {
  padding-bottom: 0 !important;
  max-height: 100%;

  .ant-modal-content {
    border-radius: 12px !important;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
  }

  .ant-modal-header {
    border-radius: 12px 12px 0 0;
    padding: 4px 0px;
    margin: 0 15px;
  }

  .ant-modal-body {
    padding: 0px;
    border-radius: 0px;
    overflow: hidden;
    height: 100%;
    height: calc(var(--app-height) - 140px);
    transition: all 0.3s ease-in;
    overflow: auto;
    position: relative;
    display: flex;

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #fff;
    }

    &::-webkit-scrollbar:horizontal {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &:hover {
      &::-webkit-scrollbar-thumb:horizontal,
      &::-webkit-scrollbar-thumb {
        background-color: #bdbdbd;
        transition: all 0.3s ease-in;
      }
    }
    &.transparent {
      &::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 0);
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: rgba(255, 255, 255, 0);
      }

      &::-webkit-scrollbar:horizontal {
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        transition: all 0.3s ease-in;
      }

      &::-webkit-scrollbar-thumb:horizontal {
        background-color: transparent;
        transition: all 0.3s ease-in;
      }

      &:hover {
        &::-webkit-scrollbar-thumb:horizontal,
        &::-webkit-scrollbar-thumb {
          background-color: #bdbdbd00;
          transition: all 0.3s ease-in;
        }
      }
    }
    .pdf-viewer-document-container {
      flex: 1;
      overflow: auto;
    }
    .react-pdf__Document {
      & > div.pdf-viewer-pages-container {
        transform-origin: top left;
        padding-block: 10px;
        padding-inline: 8px;
        & > * + * {
          margin-top: 5px !important;
        }

        & > div.pdf-viewer-page-container {
          box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
        }
      }

      & > div.scale2 {
        transform: scale(2);
      }
      & > div.scale3 {
        transform: scale(3);
      }
      & > div.scale4 {
        transform: scale(4);
      }
      & > div.scale5 {
        transform: scale(5);
      }
    }
    .react-pdf__Page {
      //   transform-origin: top left;
      canvas {
        object-fit: contain;
        object-position: center;
        height: 100% !important;
        width: 100% !important;
        margin: 0 auto;
      }
    }
    .react-pdf__Page__textContent,
    .react-pdf__Page__annotations {
      display: none !important;
    }
  }

  .title {
    font-weight: 600;
    color: var(--text1) !important;
    margin: 0 30px 0 0 !important;
    font-size: 17px;
    padding: 12px 0;
  }
  .footerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 12px;
    .pageNumber {
      font-size: 14px;
      font-weight: 600;
      color: var(--text1);
      margin: 0 12px 0 8px !important;
    }
    .buttonWrapper {
      display: flex;
      align-items: center;
      .ant-btn {
        border-radius: 4px;
        border: none;
        padding: 4px;
        height: 40px;
        width: 40px;
        font-size: 14px;
        font-weight: 600;
        background-color: var(--tertiary);
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: var(--icon);
          height: 25px;
          width: 25px;
        }
        &:not(:last-child) {
          margin-right: 4px;
        }
        &[disabled] {
          svg {
            color: var(--secondary);
          }
        }
      }
    }
  }
}
