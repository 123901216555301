.fileSelectorModal {
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: 100%;

  .ant-modal-content {
    height: calc(var(--app-height));
    display: flex;
    flex-direction: column;
  }

  .ant-modal-header {
    padding: 0px;
    border: none;
  }

  .ant-modal-body {
    padding: 0px;
    border-radius: 0px;
    overflow: hidden;
    height: 100%;
    max-height: calc(var(--app-height) - 60px);
    transition: all 0.3s ease-in;
    overflow-y: auto;
    position: relative;
    background-color: #000;

    display: flex;
    align-items: center;
    justify-content: center;

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &:hover {
      &::-webkit-scrollbar-thumb:horizontal,
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        transition: all 0.3s ease-in;
      }
    }
  }
  .siteBtnPrimary {
    width: 100%;
  }
}
