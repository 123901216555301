.section-accordion {
  padding: 12px;
  margin-bottom: 6px;
  background-color: var(--background);
  .section-accordion__title {
    .section-accordion__title__pill {
      font-size: 10px;
      font-weight: 500;
      color: var(--text2);
      background-color: var(--gray_light);
      padding: 4px 12px;
      border-radius: 20px;
      width: max-content;
      margin-bottom: 4px;
      text-transform: uppercase;
    }
    .section-accordion__title__text {
      font-size: 16px;
      font-weight: 600;
      color: var(--text1);
      margin: 0;
      // margin-right: 12px;
      margin-bottom: 12px;
    }
    .section-accordion__title__icon {
      flex-shrink: 0;
      transition: transform 0.3s ease;
      &.open {
        transform: rotate(-90deg);
      }
    }
  }
  // .section-accordion__content {
  //   overflow: hidden;
  //   max-height: 1000px;
  //   transition: max-height 1s ease-in-out;

  //   &[aria-expanded='true'] {
  //     max-height: 0px;
  //     transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  //   }
  // }
}

.section-item {
  padding: 12px;
  border: 1px solid var(--border);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  .section-item__index {
    min-width: 24px;
    align-self: flex-start;
    display: flex;
    .section-item__index__text {
      font-size: 15px;
      font-weight: 500;
      color: var(--text1);
      margin: 0;
    }
  }
  .section-item__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 8px;
    .section-item__content__title {
      font-size: 13px;
      font-weight: 500;
      color: var(--text1);
      margin: 0;
      margin-bottom: 2px;
    }
    .section-item__content__extra-text {
      font-size: 11px;
      font-weight: 500;
      color: var(--text3);
      margin: 0;
    }
    .section-item__content__preview {
      font-size: 11px;
      font-weight: 500;
      color: var(--blue);
      margin: 0;
      margin-top: 2px;
      text-decoration: underline;
    }
  }
  .progress__icon--background {
    margin: 0 auto;
    height: 24px;
    width: 24px;
    border-radius: 20px;
    background-color: var(--tertiary);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
