.dm-card {
  padding: 0 12px;
  background-color: var(--background);
  display: flex;
  flex-direction: row;
  align-items: center;

  .dm-card__content {
    // border-bottom: 1px solid var(--border);
    width: calc(100vw - 84px);
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    padding: 12px 0;

    .dm-card__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .dm-card__header__title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: calc(100% - 70px);

        .dm-card__header__title {
          margin: 0;
          font-size: 16px;
          font-weight: 600;
          color: var(--text1);
        }

        .creator-tag {
          margin-left: 4px;
        }
      }

      .dm-card__header__time {
        flex-shrink: 0;
        width: max-content;
        font-size: 11px;
        font-weight: 400;
        color: var(--text2);
      }
    }

    .dm-card__message {
      display: flex;
      flex-direction: row;
      align-items: center;

      .dm-card__message__text {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        color: var(--text3);
        width: 100%;
      }

      .dm-card__message__unread-count {
        min-width: 20px;
        height: 20px;
        flex-shrink: 0;
        border-radius: 20px;
        padding: 2px 6px;
        background-color: var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;

        .dm-card__message__unread-count__text {
          font-size: 12px;
          font-weight: 500;
          color: var(--background);
          margin: 0;
        }
      }
    }
  }
}
