.confirmationModal {
    padding-bottom: 0 !important;
    max-height: calc(var(--app-height) - 90px);

    .confirmationModalMain {
        padding: 12px 0 36px 0;
        .text {
            font-size: 15px;
            color: var(--text1) !important;
            font-weight: 500;
            text-align: center;
            margin: 0;
        }
    }

    .confirmationModalFooter {
        display: flex;
        flex-direction: column;
        .modalButton {
            border-radius: 0;
            margin: 0 !important;
            border: none;
            border-top: 1px solid var(--border);
            padding: 12px;
            font-size: 15px;
            font-weight: 500;
            color: var(--text1);
            display: flex;
            align-items: center;
            justify-content: center;
            &:last-child {
                border-radius: 0 0 12px 12px;
            }
        }
    }

    .ant-modal-content {
        border-radius: 12px !important;
        box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
            inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
    }

    .ant-modal-header {
        border-radius: 12px 12px 0 0;
        padding: 12px;
        padding-top: 16px;
        border: none;
    }

    .ant-modal-footer {
        padding: 0;
        border: none;
    }

    .activityPostModalTitleWrapper {
        position: relative;
        display: block;

        .activityPostModalTitle {
            text-align: center;
            font-weight: 600;
            color: var(--text1) !important;
            margin: 0 !important;
            font-size: 17px;
        }
    }
}
